import { ExternalButtons } from "@features/360/components/ExternalButtons";
import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";
import { useEffect } from "react";
import * as THREE from "three";
import { useMapStore } from "../../../contextStore";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene4({ texture1, texture2 }: Props) {

  const { setIntroScreenVisibility, setVideoType, videoType } = useMapStore();

  useEffect(() => {
    if (videoType !== "VISIT_MUSEUM" && videoType !== "DONATION") {
      setVideoType("DONATION");
      setIntroScreenVisibility(true);
    }
  }, [])

  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />
      <ExternalButtons size={39} position={[101, 42, -26]} type="ARCHIVE" />
      <ExternalButtons size={39} position={[101, 42, 27.5]} type="DONATE" />
      <ExternalButtons size={38} position={[95, 1, 0]} type="VISIT_MUSEUM" />

      <FloorPlane
        position={[-420, GROUND_HEIGHT + 30, -10]}
        args={[50, 50, 24, 24]}
        onClick={3}
      />

      <FloorPlane
        position={[-420, GROUND_HEIGHT, -10]}
        args={[50, 50, 24, 24]}
        rotation={Math.PI}
        onClick={3}
      />

      <FloorPlane
        position={[0, GROUND_HEIGHT, -4]}
        args={[34, 34, 24, 24]}
        rotation={Math.PI}
        onClick={4}
      />
    </>
  );
}
