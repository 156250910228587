import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrap: {
    color: "#fff !important",
    fontSize: '50px',
    display: 'flex',
    borderRadius: '32px',
    backgroundColor: 'rgb(159 133 76 / 57%)', 
   },
  button:{
    color: '#fff !important',
    '&.Mui-disabled': {
      opacity: '0.5 !important',
    }
  },
  buttonActive: {
    color: '#d9c14b !important'
  },

});