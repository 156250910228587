import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Html, useProgress } from '@react-three/drei'
import { useStyles } from './styles'

export function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  const classes = useStyles()
  return (
    <Html>
      <CircularProgress className={classes.loader} />
      <Typography className={classes.text}> {progress} % loaded </Typography>
    </Html>
  )
}
