


import { useMapStore } from "@features/contextStore";


type BookTurnClickableProps = {
  height: number;
  width: number;
  onClickNext?():void;
  onClickAction?():void;
  shown: boolean;
}
const BookTurnClickable = ({ height, width, onClickNext,onClickAction,  shown}: BookTurnClickableProps) => {
  const {debug} =  useMapStore();

  if(!shown) {
    return null
  }
  return (
  <>   
   <span onClick={onClickNext} style={{
      zIndex: 999,
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      top: height/1.2,
      left: width/15,
      background: 'red', width: width/6, height: height/6
      }} > Page  </span>
    
    <span onClick={onClickAction} style={{
       zIndex: 999,
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      top: height/1.2,
      left: width/3,
      background: 'red', width: width/6, height: height/6
      }} > Action  </span>
  </>
  );
};

export default BookTurnClickable;