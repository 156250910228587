/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React from 'react'
import * as THREE from 'three'

import {useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import "@features/360/shaders/ImageFadeMaterial"
import {Scene1} from '@features/360/Scenes/Scene1'
import {Scene2} from '@features/360/Scenes/Scene2'
import {Scene3} from '@features/360/Scenes/Scene3'
import {Scene4} from '@features/360/Scenes/Scene4'
import {Scene5} from '@features/360/Scenes/Scene5'
import {Scene6} from '@features/360/Scenes/Scene6'
import {Scene7} from '@features/360/Scenes/Scene7'
import {Scene8} from '@features/360/Scenes/Scene8'
import {Scene9} from '@features/360/Scenes/Scene9'
import {Scene10} from '@features/360/Scenes/Scene10'
import {Scene11} from '@features/360/Scenes/Scene11'
import {Scene12} from '@features/360/Scenes/Scene12'
import { useEffect } from 'react'
import { useMapStore } from '../../contextStore';


const files = [
  "/3D/meausium/low/Cam_01.png",
  "/3D/meausium/low/Cam_02.png",
  "/3D/meausium/low/Cam_03.png",
  "/3D/meausium/low/Cam_04.png",
  "/3D/meausium/low/Cam_05.jpg",
  "/3D/meausium/low/Cam_06.jpg",
  "/3D/meausium/low/Cam_07.jpg",
  "/3D/meausium/low/Cam_08.jpg",
  "/3D/meausium/low/Cam_09.jpg",
  "/3D/meausium/low/Cam_10.jpg",
  "/3D/meausium/low/Cam_11.jpg",
  "/3D/meausium/low/Cam_12.jpg",
];
  
type Props = {
 
}
export function Scenes(props: Props) {

  const {map} = useMapStore();
  const textures = useLoader(TextureLoader, files)
  useEffect(() => {
    files.forEach((value, index) =>{
      textures[index].wrapS = THREE.RepeatWrapping;
      textures[index].repeat.x = - 1;
      textures[index].mapping = THREE.EquirectangularReflectionMapping;
      textures[index].magFilter = THREE.LinearFilter;
      textures[index].encoding = THREE.sRGBEncoding;
    })
},textures)

  const current = textures[map -1];

  return (
    <>
    <ambientLight intensity={0.4} />
      <directionalLight
        castShadow
        position={[2.5, 8, 5]}
                intensity={1.5}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
                shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      <pointLight position={[-10, 0, -20]} color="red" intensity={2.5} />
      <pointLight position={[0, -10, 0]} intensity={1.5} />
      <mesh>
       <sphereBufferGeometry   attach="geometry" args={[500, 60, 40]} />
        { map === 1 &&
           <Scene1 texture1={current}  texture2={current} />
        }
        { map === 2 &&
           <Scene2 texture1={current}  texture2={current} />
        }
        { map === 3 &&
           <Scene3 texture1={current}  texture2={current} />
        }
        { map === 4 &&
           <Scene4 texture1={current}  texture2={current} />
        }
        { map === 5 &&
           <Scene5 texture1={current}  texture2={current} />
        }
        { map === 6 &&
           <Scene6 texture1={current}  texture2={current} />
        }
        { map === 7 &&
           <Scene7 texture1={current}  texture2={current} />
        }
        { map === 8 &&
           <Scene8 texture1={current}  texture2={current} />
        }
        { map === 9 &&
           <Scene9 texture1={current}  texture2={current} />
        }
        { map === 10 &&
           <Scene10 texture1={current}  texture2={current} />
        }
         { map === 11 &&
           <Scene11 texture1={current}  texture2={current} />
        }
         { map === 12 &&
           <Scene12 texture1={current}  texture2={current} />
        }
        { map === 13 &&
           <Scene1 texture1={current}  texture2={current} />
        }
      </mesh>
    </>
  )
}
