import PlayButton from '@features/360/components/PlayButton'
import { Box, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import videoPoster from '../../../../../../../src/assets/video_poster.jpeg'
import { VideoKeys } from '../../constants'

type VideoPlayerProps = {
  width: number
  height: number
  src: string
  media: VideoKeys
  onEnd?(): void
  loop?: boolean
  playsInline?: boolean
  poster?: string
  disablePictureInPicture?: boolean
  disableRemotePlayback?: boolean
  controls?: boolean
  onTimeUpdate?: any,
  showPoster?: boolean;
}
const IntroVideoPlayer = ({
  showPoster,
  loop,
  width = 932,
  height = 535,
  src,
  media,
  onEnd,
  onTimeUpdate,
  ...otherProps
}: VideoPlayerProps) => {
  const vidRef = useRef<any>(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)

  function handlePlayVideo() {
    // vidRef.current.playbackRate = 15
    const playPromise = vidRef.current?.play()
    if (playPromise !== undefined) {
      playPromise.
        then(() => {
          setIsVideoPlaying(true)
        })
        .catch((err: Error) => {
          setIsVideoPlaying(false)
          console.log("Auto play was prevented", err)
        })
    }
  }

  const onEnded = () => {
    if (onEnd) {
      onEnd()
    }
  }

  useEffect(() => {
    if (["video_2", "video_3", "video_4", "video_5"].includes(media)) {
      handlePlayVideo()
    }
  }, [media])
  
  if(height < width) {
    return (
      <Grid component="div" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <video
          loop={loop}
          style={{
            objectFit: 'fill',
          }}
          muted={false}
          preload="auto"
          ref={vidRef}
          src={src}
          width='100%'
          height={'auto'}
          playsInline
          onEnded={onEnded}
          poster={showPoster ? videoPoster : undefined }
          onTimeUpdate={onTimeUpdate}
          onPause={ () => setIsVideoPlaying(false) }
          {...otherProps}
        />
        {!isVideoPlaying && <PlayButton handlePlayVideo={handlePlayVideo} />}
      </Grid>
    )
  }
    return (
      <Grid component="div" sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 160px);',
      }}>
        <video
          loop={loop}
          style={{
            objectFit: 'cover',
          }}
          onError={e =>{ 
            alert(vidRef.current?.error?.message || 'Cannot play video'); 
            console.log(vidRef.current?.error)
          }}
          muted={false}
          preload="auto"
          ref={vidRef}
          src={src}
          width={width}
          height='auto'
          playsInline
          onEnded={onEnded}
          poster={showPoster ? videoPoster : undefined }
          onTimeUpdate={onTimeUpdate}
          onPause={ () => setIsVideoPlaying(false) }
          {...otherProps}
        />
        {!isVideoPlaying && <PlayButton handlePlayVideo={handlePlayVideo} />}
      </Grid>
    )
  

}

export default IntroVideoPlayer
