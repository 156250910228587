import * as THREE from "three";

import { Pin } from "@features/360/components/Pin";
import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene5({ texture1, texture2 }: Props) {
  return (
    <>
      <Pin position={[124.3, 1.5, -27.02]} />
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />


      <FloorPlane
        position={[90, GROUND_HEIGHT, 78]}
        args={[30, 30, 24, 24]}
        onClick={1}
      />


      <FloorPlane
        position={[110, GROUND_HEIGHT, 30]}
        args={[34, 34, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[20, GROUND_HEIGHT, 2]}
        args={[28, 28, 24, 24]}
        onClick={5}
      />


      <FloorPlane
        position={[180, GROUND_HEIGHT, 45]}
        args={[24, 24, 24, 24]}
        onClick={6}
      />

      <FloorPlane
        position={[220, GROUND_HEIGHT, -20]}
        args={[24, 24, 24, 24]}
        onClick={7}
      />
      <FloorPlane
        position={[-28, GROUND_HEIGHT, -25]}
        args={[34, 34, 24, 24]}
        onClick={8}
      />


      <FloorPlane
        position={[220, GROUND_HEIGHT, 30]}
        args={[18, 18, 24, 24]}
        onClick={10}
      />



    </>
  );
}
