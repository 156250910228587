import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrap: {
    color: "#fff !important",
    fontSize: '50px',
    display: 'flex',
    borderRadius: '32px',
    backgroundColor: 'rgb(159 133 76 / 57%)', 
   },
  button:{
    color: '#fff !important'
  },
  buttonActive: {
    color: '#d9c14b !important'
  },
  map: {
    width: 30,
    height: 30,
    margin: 8,
    color: '#d9c14b !important',
    backgroundColor: '#c08331',
    fontSize: 20,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  }
});