import { IS_FULLSCREEN_VIDEOS } from "@utils/constants"

export const FULL = {
    '1': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/1.66,
            width: width/8, 
            height: height/3.2
        }
    },
    '2': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/2.27,
            width: width/8, 
            height: height/3.2
        }
    },
    '4': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/3.7,
            width: width/8, 
            height: height/3.2
        }
    },
    '5': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/1.66,
            width: width/8, 
            height: height/3.2
        }
    },
    '6': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/2.27,
            width: width/9, 
            height: height/3.2
        }
    },
    '10': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/3.7,
            width: width/9, 
            height: height/3.2
        }
    }
}

export const HALF = {
    '1': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/2.6,
            width: width/10, 
            height: height/3.2
        }
    },
    '2': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/4.6,
            width: width/10, 
            height: height/3.2
        }
    },
    '4': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/18.3,
            width: width/10, 
            height: height/3.2
        }
    },
    '5': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/2.6,
            width: width/10, 
            height: height/3.2
        }
    },
    '6': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/4.6,
            width: width/10, 
            height: height/3.2
        }
    },
    '10': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/18.3,
            width: width/10, 
            height: height/3.2
        }
    }
}
export const POSITIONS = IS_FULLSCREEN_VIDEOS ?  FULL : HALF

export type positionsKeys = keyof typeof POSITIONS
export const POSITION_KEYS = Object.keys(POSITIONS) as any;