

import BookListVideo from "@features/Museum/components/BookListVideo";

const MuseumApp = () => {

  return (
    <BookListVideo />
  );
};

export default MuseumApp;
