import { Box, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import React, { SyntheticEvent, useRef } from 'react'
import { PlayIcon } from './PlayIcon'
import { useStyles } from './styles'
import icon from './assets/play-icon.png';
type PlayButtonProps = { handlePlayVideo: any }

function PlayButton(props: PlayButtonProps) {
  const playBtnRef = useRef<any>(null)
  const classes = useStyles()
  const onClickFullScreen = () => {
    const doc: any = document;
    try {
      if (!doc.fullscreenElement && // alternative standard method
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement) {
        if (doc.body.requestFullscreen) {
          return doc.body.requestFullscreen();
        } else if (doc.body.msRequestFullscreen) {
          return doc.body.msRequestFullscreen();
        } else if (doc.body.mozRequestFullScreen) {
          return doc.body.mozRequestFullScreen();
        } else if (doc.body.webkitRequestFullscreen) {
          return doc.body.webkitRequestFullscreen();
        }
      } else if (doc.exitFullscreen) {
        return doc.exitFullscreen();
      } else if (doc.msExitFullscreen) {
        return doc.msExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        return doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        return doc.webkitExitFullscreen();
      }
      alert("Full Screen is not supported");
      
    } catch (e) {
      console.log('error:', e)
      alert("Full Screen is not supported");
    }
  };

  function handlePlayButtonClick(e: SyntheticEvent) {
    e.preventDefault()
    props.handlePlayVideo(e)
    onClickFullScreen()
    playBtnRef.current.styles = { visibility: "none" }
  }

  return (
    <Box
      component="div"
      className={clsx([classes['play-btn-container']])}
      ref={playBtnRef}
      onClick={handlePlayButtonClick}
    >
      <IconButton className={clsx([classes.playbtn])}>
        <img src={icon} alt="Play icon" />
      </IconButton>
    
    </Box>
  )
}

export default PlayButton
