export const POSITIONS = {
    '1': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/1.7    ,
        }
    },
    '2': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/2.27
        }
    },
    '4': (height: number, width: number) => {
        return{
            top: height/14,
            left: width/3.4,
        }
    },
    '5': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/1.7    ,
        }
    },
    '6': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/2.27,
        }
    },
    '10': (height: number, width: number) => {
        return{
            top: height/2.5,
            left: width/3.4,
        }
    }
}



export type positionsKeys = keyof typeof POSITIONS
export const POSITION_KEYS = Object.keys(POSITIONS) as any;
