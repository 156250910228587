
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const IN_HOUSE_APP = process.env.REACT_APP_IN_HOUSE === 'true';
console.log( process.env)

export const IS_PRODUCTION =  process.env.PRODUCTION === 'true';
export const GROUND_HEIGHT = -24.9;
export const ROTATION_DELTA = 0.15;

export const INITIAL_CAMERA = {
    position: [
        -4.996776305590624, -0.17427601590100508, 0.04306300231009078
    ] as [number , number, number],
    zoom: 1,
    fov: 70
}

export const IS_FULLSCREEN_VIDEOS = process.env.REACT_APP_FULLSCREEN_VIDEOS === "true"
