import { AppBar, Toolbar, Typography, Button, Grid, Box, Hidden, Drawer, List, ListItemButton, ListItemText, IconButton } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { useStyles } from './styles';
const Header = () => {
  const classes = useStyles()
  return (
    <>
    <AppBar className={classes.root} sx={{ position: "relative", }}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgDown>
          <Typography variant="h6" component="div" className={classes.head}>
            The Book of Honor Museum</Typography>
        </Hidden>
        <Button  component={Link} to={'/'} sx={{ position: "absolute", minWidth: '75px' }} className={classes.image}> <img style={{ width: "100%", height: "100%" }} alt="Logo" src='/logo.png' /> </Button>
        <Grid className={classes.right}>
          <Hidden mdDown>
            <Typography color="primary"  >Honor Books Archive</Typography>
          </Hidden>
          <Box component="div">
            <Button  component={'a'} target='_blank' href='https://booksofhonor.kkl.org.il/#donate' className={classes.btn} variant="contained"> Donations </Button>
            {/* <Button component={Link} to={'/'} variant="outlined" > ENG </Button> */}
          </Box>
        </Grid>

      </Toolbar>
    </AppBar>
    
    </>
  );
};
export default Header;