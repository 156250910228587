import * as THREE from "three";

import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene10({ texture1, texture2 }: Props) {
  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[300, GROUND_HEIGHT, -20]}
        args={[34, 34, 24, 24]}
        onClick={1}
      />   
      
      <FloorPlane
        position={[150, GROUND_HEIGHT, 190]}
        args={[34, 34, 24, 24]}
        onClick={3}
      />

      <FloorPlane
        position={[190, GROUND_HEIGHT, -10]}
        args={[34, 34, 24, 24]}
        onClick={6}
      />

      <FloorPlane
        position={[35, GROUND_HEIGHT, 110]}
        args={[38, 38, 24, 24]}
        onClick={7}
      />

      <FloorPlane
        position={[15, GROUND_HEIGHT, 5]}
        args={[38, 38, 24, 24]}
        onClick={10}
      />


      <FloorPlane
        position={[90, GROUND_HEIGHT, -170]}
        args={[34, 34, 24, 24]}
        onClick={11}
      />
    </>
  );
}
