import { museumContextStore } from "@features/Museum/museumContextStore";
import Grid from "@mui/material/Grid";
import { useWindowSize } from "@utils/hooks/useWindowSize";
import React, { useEffect } from "react";
import FlipBook from "../FlipBookVideo";
import { useStyles } from "./styles";

const BookList = () => {
  const classes = useStyles();
  const { height, width } = useWindowSize();
  const { fullScreen } = museumContextStore();

  const requestFullScreen = (element: any) => {
    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;
    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    }
  };

  useEffect(() => {
    if (fullScreen) {
      try {
        console.log("Requesting full screen..");
        requestFullScreen(document.body);
      } catch (e) {
        console.log("Full Screen is not supported");
      }
    } else {
      try {
        console.log("leave full screen");
        document.exitFullscreen();
      } catch (e) {
        console.log("Full Screen is not supported");
      }
    }
  }, [fullScreen]);
  useEffect(() => {
    function handleClickOutside(event: any) {
      console.log("You clicked on screen", event.target);
    }
    console.log("regestering global click");
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [])

  if (height && width) {
    const dialogHeight = height ;
    const dialogWidth = width
    return (
      <div className={classes.root}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <FlipBook width={dialogWidth} height={dialogHeight} />
        </Grid>
      </div>
    );
  }
  return null;
};
export default BookList;
