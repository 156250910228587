import { useMapStore } from "@features/contextStore";
import React from "react";
import {  positionsKeys, POSITIONS } from "./constants";


type BookThumbnailProps = {
  number: positionsKeys;
  height: number;
  width: number;
  onClick?(n: string):void;
  shown: boolean;
}
const BookThumbnail = ({number, height, width, onClick, shown}: BookThumbnailProps) => {
  const {debug} = useMapStore();
  const handleOnclick= ()=>{
    if(onClick) {
      onClick(number)
    }
  }
  if(!shown) {
    return null
  }
  return (
    <span onClick={handleOnclick} style={{
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      ...POSITIONS[number](height, width),
      background: 'red', width: width/9, height: height/4.1
      }} > Book  {number} </span>
  );
};

export default BookThumbnail;