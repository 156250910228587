import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions =  {
  overline: {
    fontWeight: 500,
  },
  allVariants: {
    textTransform: 'none'
  },
  fontFamily: [
    'Open Sans',
  ].join(','),
};
