import { ExternalButtons } from "@features/360/components/ExternalButtons";
import { FloorPlane } from "@features/360/components/Floor";
import { Pin } from "@features/360/components/Pin";
import { GROUND_HEIGHT } from "@utils/constants";
import * as THREE from "three";


type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene1({ texture1, texture2 }: Props) {

  return (
    <>
      <Pin />
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />
      
      <FloorPlane
        position={[18, GROUND_HEIGHT, -1]}
        args={[24, 24, 24, 24]}
        onClick={1}
      />

      <FloorPlane
        position={[62, GROUND_HEIGHT, 1]}
        args={[24, 24, 24, 24]}
        onClick={2}
      />


      <FloorPlane
        position={[220, GROUND_HEIGHT, 5]}
        args={[28, 28, 24, 24]}
        onClick={3}
      />


      <FloorPlane
        position={[53, GROUND_HEIGHT, -90]}
        args={[28, 28, 24, 24]}
        onClick={5}
      />


      <FloorPlane
        position={[70, GROUND_HEIGHT, 70]}
        args={[28, 28, 24, 24]}
        onClick={6}
      />
      
      <FloorPlane
        position={[62, GROUND_HEIGHT, -130]}
        args={[20, 20, 24, 24]}
        onClick={8}
      />

      <FloorPlane
        position={[170, GROUND_HEIGHT, -85]}
        args={[26, 26, 24, 24]}
        onClick={9}
      />

 

      <FloorPlane
        position={[-55, GROUND_HEIGHT, -47]}
        args={[24, 24, 24, 24]}
        onClick={12}
      />

    </>
  );
}
