import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '14px !important',
    color: "#C6AC73"
  },
})
