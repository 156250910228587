import * as THREE from "three";

import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene12({ texture1, texture2 }: Props) {
  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[115, GROUND_HEIGHT, 5]}
        args={[24, 24, 24, 24]}
        onClick={1}
      />

      <FloorPlane
        position={[155, GROUND_HEIGHT, -20]}
        args={[24, 24, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[80, GROUND_HEIGHT, -150]}
        args={[24, 24, 24, 24]}
        onClick={8}
      />

      <FloorPlane
        position={[120, GROUND_HEIGHT, 100]}
        args={[26 , 26 , 24, 24]}
        onClick={11}
      />

      <FloorPlane
        position={[20, GROUND_HEIGHT, -5]}
        args={[30, 30, 24, 24]}
        onClick={12}
      />

    </>
  );
}
