import {isValidPhoneNumber} from 'libphonenumber-js'
export const validateEmail = (email: string) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  export const validatePhone = (phone: string) => {
   const phoneNumValidation = isValidPhoneNumber(phone) || isValidPhoneNumber(phone, 'IL')
   console.log({[phone]: phoneNumValidation})
    return phoneNumValidation
  };
