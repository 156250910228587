import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";
import * as THREE from "three";


type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene7({ texture1 }: Props) {
  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[200, GROUND_HEIGHT, -20]}
        args={[34, 34, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[12, GROUND_HEIGHT, -2]}
        args={[34, 34, 24, 24]}
        onClick={7}
      />


      <FloorPlane
        position={[50, GROUND_HEIGHT, -80]}
        args={[34, 34, 24, 24]}
        onClick={10}
      />

      <FloorPlane
        position={[60, GROUND_HEIGHT, 120]}
        args={[30, 30, 24, 24]}
        onClick={11}
      />

      <FloorPlane
        position={[180, GROUND_HEIGHT, 160]}
        args={[34, 34, 24, 24]}
        onClick={12}
      />
    </>
  );
}
