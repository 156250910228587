import { hibAudio, playAudio } from '@features/360/audio';
import { useMapStore } from "@features/contextStore";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Mesh, Object3D, TextureLoader } from "three";


export function Pin({ position =[115, 1, 5.5] }: any) {
  const {modal, rotation, toggleModal} = useMapStore()
  const sphere = useRef<Mesh>(null!)
  const mouse = useRef<any>(position)
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : rotation ? 'move' : 'auto'
  }, [hovered])
 
  useFrame(({ clock, camera }) => {
      sphere.current.position.y = Math.sin(clock.getElapsedTime()) + -2
  })

  const  handleClick = () => {
    playAudio(hibAudio);
    toggleModal(true)
  }
  const image = useLoader(TextureLoader, `/images/pin/pin.png`);

  return (
    <group ref={sphere}  position={position}   onClick={handleClick }
    onPointerOver={(event) => hover(true)}
    onPointerOut={(event) => hover(false)}  scale={modal ? 1.2 : 1} >   
      <sprite scale={[20, 20, 20]} >
        <spriteMaterial map={image} />
      </sprite>
     <Swarm count={300} mouse={mouse} />
    </group>
  );
}

function Swarm({ count, mouse }: any) {
  const mesh = useRef<any>()
  const light = useRef<any>()
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width

  const dummy = useMemo(() => new Object3D(), [])

  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 50 + Math.random() * 100
      const speed = 0.01 + Math.random() / 200
      const xFactor = mouse.current[0] + Math.random() * 10
      const yFactor = mouse.current[1] + Math.random() * 10
      const zFactor =  mouse.current[2] + Math.random() * 10
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  // The innards of this hook will run every frame
  useFrame(state => {
    // Makes the light follow the mouse
    light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 2
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t)
      particle.mx += (mouse.current[0] - particle.mx) * 0.05
      particle.my += (mouse.current[1] * -1 - particle.my) * 0.05
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
      )
      dummy.scale.set(s, s, s)
      dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <>
      <pointLight ref={light} distance={40} intensity={8} color="lightblue" />
      <instancedMesh ref={mesh} args={[null, null, count] as any}>
        <dodecahedronBufferGeometry attach="geometry" args={[0.4, 0]} />
        <meshPhongMaterial attach="material" color="#ab2822" />
      </instancedMesh>
    </>
  )
}