


import { museumContextStore } from "@features/Museum/museumContextStore";
import { IS_FULLSCREEN_VIDEOS } from "@utils/constants";
import React from "react";


type BookTurnClickableProps = {
  height: number;
  width: number;
  onClickNext?(): void;
  onClickAction?(): void;
  shown: boolean;
}
const BookTurnClickable = ({ height, width, onClickNext, onClickAction, shown }: BookTurnClickableProps) => {
  const { debug } = museumContextStore();

  if (!shown) {
    return null
  }
  const positionNext = IS_FULLSCREEN_VIDEOS ? {
    top: height /1.25,
    left: width / 8,
    background: 'red', width: width / 10, height: height / 6
  } : {
    top: height / 1.2,
    left: width / 15,
    background: 'red', width: width / 6, height: height / 6
  }

  const positionAction = IS_FULLSCREEN_VIDEOS ? {
    top: height / 1.25,
    left: width / 2.8,
    background: 'red', width: width / 7, height: height / 6
  } : {
    top: height / 1.2,
    left: width / 3,
    background: 'red', width: width / 6, height: height / 6
  }
  return (
    <>
      <span onClick={onClickNext} style={{
        zIndex: 999,
        cursor: 'pointer',
        opacity: debug ? 0.4 : 0,
        position: 'absolute',
        ...positionNext
      }} > Page  </span>

      <span onClick={onClickAction} style={{
        zIndex: 999,
        cursor: 'pointer',
        opacity: debug ? 0.4 : 0,
        position: 'absolute',
        ...positionAction
      }} > Action  </span>
    </>
  );
};

export default BookTurnClickable;