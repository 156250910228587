import { museumContextStore } from "@features/Museum/museumContextStore";
import Fullscreen from "@mui/icons-material/Fullscreen";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { BookKeys, BookVideoKeys, VIDEOS } from '../constants';
import BookMenu from "../elements/BookMenu";
import BookThumbnail from "../elements/BookThumbnail";
import { positionsKeys, POSITION_KEYS } from "../elements/BookThumbnail/constants";
import VideoPlayer from "../VideoPlayer";
import { useStyles } from "./styles";

type FlipBookProps = {
  width: number,
  height: number,
}


const FlipBook = ({ width, height }: FlipBookProps) => {
  const classes = useStyles();
  const [selectedBook, setSelectedBook] = useState<BookKeys>(`book1`)
  const [media, setMedia] = useState<BookVideoKeys>(`${selectedBook}.cuts_1`)
  const { debug, fullScreen, toggleDebug, toggleFullScreen } = museumContextStore();
  const onEnd = (m?: BookVideoKeys) => {
    if (m) {
      setMedia(m);
      return
    }
    if (media === `${selectedBook}.cuts_37`) {
      setMedia(`${selectedBook}.cuts_1`);
      return
    }
    const num = parseInt(media.split('_')[1])
    const newMedia = `${[selectedBook]}.cuts_${num + 1}` as BookVideoKeys
    setMedia(newMedia)
  }

  const onSelectBook = (number: string) => {
    switch (number) {
      case '1':
        setSelectedBook("book1")
        setMedia('book1.cuts_2')
        break;
      case '2':
        setSelectedBook("book2")
        setMedia('book2.cuts_2')
        break;
      case '4':
        setSelectedBook("book4")
        setMedia('book4.cuts_2')
        break;
      case '5':
        setSelectedBook("book5")
        setMedia('book5.cuts_2')
        break;
      case '6':
        setSelectedBook("book6")
        setMedia('book6.cuts_2')
        break;
      case '10':
        setSelectedBook("book10")
        setMedia('book10.cuts_2')
        break;
      default:
        alert(`Book not implemented ${number}`)
        break;
    }

  }

  const onClickMenu = () => {
    setMedia(`${selectedBook}.cuts_1`)
  }

  // const onClickDebug = () => {
  //   toggleDebug(!debug)
  // }

  const onClickFullScreen = () => {
    toggleFullScreen(!fullScreen)
  }

  return (
    <div id="video-player" style={{
      position: 'relative',
      background: '#000',
      width,
      height,
    }}>
      <VideoPlayer key={`${selectedBook}.cuts_1`} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_1`]} width={width} height={height} />
      <VideoPlayer key={2} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_2`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={3} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_3`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={4} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_4`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={5} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_5`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={6} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_6`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={7} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_7`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={8} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_8`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={9} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_9`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={10} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_10`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={11} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_11`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={12} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_12`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={13} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_13`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={14} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_14`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={15} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_15`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={16} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_16`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={17} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_17`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={18} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_18`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={19} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_19`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={20} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_20`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={21} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_21`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={22} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_22`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={23} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_23`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={24} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_24`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={25} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_25`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={26} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_26`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={27} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_27`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={28} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_28`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={29} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_29`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={30} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_30`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={31} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_31`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={32} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_32`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={33} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_33`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={34} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_34`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={35} selectedBook={selectedBook} loop={true} media={media} src={VIDEOS[selectedBook][`cuts_35`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={36} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_36`]} width={width} height={height} onEnd={onEnd} />
      <VideoPlayer key={37} selectedBook={selectedBook} media={media} src={VIDEOS[selectedBook][`cuts_37`]} width={width} height={height} onEnd={onEnd} />
      {POSITION_KEYS.map((m: positionsKeys) => {
        return <BookThumbnail key={m} onClick={onSelectBook} shown={media === `${selectedBook}.cuts_1`} height={height} width={width} number={m} />
      })}

      <BookMenu onClickMenu={onClickMenu} width={width} height={height} />
      <span className={classes.wrap} >
        <IconButton className={classes.button} onClick={onClickFullScreen}>
          <Fullscreen />
        </IconButton>
        {debug &&
          <label style={{color: "#fff"}}>  Playing: {media}  </label>
        }
      </span>
    </div>
  );
};

export default FlipBook;