import BorderLinearProgress from '@features/360/components/BorderLinearProgress'
import { Box, IconButton, Grid, Typography, useMediaQuery } from '@mui/material'
import { useWindowSize } from '@utils/hooks/useWindowSize'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import IntroVideoPlayer from './components/IntroVideoPlayer'
import { useStyles } from './styles'
import { VIDEOS, VideoKeys } from './constants'
import skipIcon from './assets/skip-icon.png';
import closeIcon from './assets/close-icon.png';
import theme from '@theme/styles/theme/mui'
type IntroVideoProps = {
  isIntroVideoVisible: boolean
  setIsIntroVideoVisible: (isVisible: boolean) => undefined | void,
  videoType: string;
}

function IntroVideo(props: IntroVideoProps) {
  const { setIsIntroVideoVisible, videoType } = props
  const { height, width } = useWindowSize()
  const [media, setMedia] = useState<VideoKeys>(`video_1`)
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles()



  function onEnd() {
    switch (media) {
      case "video_1":
        setMedia("video_2");
        break;
      case "video_2":
        setMedia("video_3");
        break;
      case "video_3":
        setIsIntroVideoVisible(false);
        break;
      case "video_4":
        setIsIntroVideoVisible(false);
        break;
      case "video_5":
        setIsIntroVideoVisible(false);
        break;
      default:
        setMedia("video_1");
        break;
    }
  }
  const [progress, setProgress] = useState({
    video_1: 0,
    video_2: 0,
    video_3: 0,
  })
  const videoNumber = media.split("_")[1]
  const introVideos = ['video_1', 'video_2', 'video_3']

  const onTimeUpdate = (e: React.ChangeEvent<HTMLMediaElement>): undefined => {
    if (isNaN(e.target.duration)) return undefined
    const newProgressValue = {
      ...progress,
      [media]: (e.target.currentTime / e.target.duration) * 100,
    }
    setProgress(newProgressValue)
  }

  useEffect(() => {
    if (videoType === "DONATION") {
      setMedia("video_4")
    }
    if (videoType === "VISIT_MUSEUM") {
      setMedia("video_5");
    }
  }, [videoType])
  
  const handleNext = () => {
    setMedia("video_3")
  }

  const handleClose = () => {
    setIsIntroVideoVisible(false);
  }
  let showPoster 
  if (media === "video_1" || media === "video_2" || media === "video_3") {
    showPoster = true
  } else {
    showPoster = false
  }
  console.log({media})
  if (width && height) {
    return (
      <Box component="div" className={clsx(matches ? classes.mainScreenSmall :  classes.mainScreen)}>
        <Grid component="div">
            <IntroVideoPlayer
              loop={false}
              width={width}
              height={height}
              media={media}
              src={VIDEOS[media]}
              onEnd={onEnd}
              onTimeUpdate={onTimeUpdate}
              showPoster={showPoster}
            />
          <Box component="div">
            {introVideos.includes(media) && (
              <>
                <Box component="div" className={clsx([classes.progressbar])}>
                  <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                    <Grid item lg={4} sm={4} xs={4}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress[`video_1`]}
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress[`video_2`]}
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress[`video_3`]}
                      />
                    </Grid>
                  </Grid>
                </Box>
                
                <Box component="div" className={clsx([classes.bottomtxt])}>
                
                  <Typography className={clsx([classes.txt, classes.title])}>
                    {media==="video_1" && "Please watch 3 Opening videos in order to reach the museum"}
                  </Typography>
                  <Typography className={clsx([classes.txt, classes.desc])}>
                    {`${videoNumber} of 3 videos`}
                  </Typography>
                    {media==="video_2" &&
                      <IconButton className={classes.skipButton} onClick={handleNext} >
                           <img src={skipIcon}/>
                      </IconButton>
                    }
                </Box>
              </>
            )}
            {media==="video_5" &&
                    <Box component="div" className={clsx([classes.bottomButton])}>
                      <IconButton className={classes.closeButton} onClick={handleClose} >
                           <img src={closeIcon}/>
                      </IconButton>
                      </Box>
                    }
          </Box>
        </Grid>
       </Box>
    )
  }
  return null
}
export default IntroVideo