import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flex:1,
    backgroundColor: '#ccc',
  
  },
  modal: {
    zIndex: '99999999 !important',
    '& .MuiPaper-root': {
     margin: '0px !important' 
    }
  }
});