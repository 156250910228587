


import { museumContextStore } from "@features/Museum/museumContextStore";
import { IS_FULLSCREEN_VIDEOS } from "@utils/constants";
import React from "react";


type BookMenuProps = {
  height: number;
  width: number;
  onClickMenu?():void;
}
const BookMenu = ({height, width, onClickMenu}: BookMenuProps) => {
  const {debug} =  museumContextStore();
  const handleOnclick= ()=>{
    if(onClickMenu) {
      onClickMenu()
    }
  }

  const position = IS_FULLSCREEN_VIDEOS ? {
    top:  10,
    left: 10,
    background: 'red', width: width/18, height: height/10
  } : {
    top:  10,
    left: 10,
    background: 'red', width: width/18, height: height/10
  }
  return (
    <span onClick={handleOnclick} style={{
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      ...position
      }} >  </span>
  );
};

export default BookMenu;