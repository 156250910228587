import { useMapStore } from "../../contextStore";
import hib from './HighlightInsideBook.mp3';

export const hibAudio = new Audio(hib);

export function playAudio(audio: { currentTime: number; volume: number; loop: boolean; play: () => void; pause: () => void }, volume = 1, loop = false) {
    if (useMapStore.getState().sound) {
      audio.currentTime = 0
      audio.volume = volume
      audio.loop = loop
      audio.play()
    } else audio.pause()
  }
