import React from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import MuiTheme from './mui';

export const ThemeProvider = (props: { children: React.ReactChild }) => {


  return (
    <MuiThemeProvider theme={MuiTheme}>
        {React.Children.only(props.children)}
    </MuiThemeProvider>
  );
};
