import { useMapStore } from "@features/contextStore";
import BugReport from "@mui/icons-material/BugReport";
import Fullscreen from "@mui/icons-material/Fullscreen";
import InfoRounded from "@mui/icons-material/InfoRounded";
import Refresh from "@mui/icons-material/Refresh";
import VolumeOff from "@mui/icons-material/VolumeOff";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Html, Stats } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { INITIAL_CAMERA, ROTATION_DELTA } from "@utils/constants";
import clsx from "clsx";
import { useEffect } from 'react';
import { Vector3 } from "three";
import { MoveIcon } from "./assets/MoveIcon";
import { useStyles } from "./styles";
const ZINDEX = 0
type Props = {

};
export function Toolbar({  }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { camera , size} = useThree();
  const {map,resetting, sound, debug, isDebugApplicable, rotation, arrow, resetAll, toggleSound, toggleRotation, toggleDebug} = useMapStore();
  const onClickFullScreen = () => {
    const doc: any = document;
    try {
      if (!doc.fullscreenElement && // alternative standard method
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement) {
        if (doc.body.requestFullscreen) {
          return doc.body.requestFullscreen();
        } else if (doc.body.msRequestFullscreen) {
          return doc.body.msRequestFullscreen();
        } else if (doc.body.mozRequestFullScreen) {
          return doc.body.mozRequestFullScreen();
        } else if (doc.body.webkitRequestFullscreen) {
          return doc.body.webkitRequestFullscreen();
        }
      } else if (doc.exitFullscreen) {
        return doc.exitFullscreen();
      } else if (doc.msExitFullscreen) {
        return doc.msExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        return doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        return doc.webkitExitFullscreen();
      }
      alert("Full Screen is not supported");
      
    } catch (e) {
      console.log('error:', e)
      alert("Full Screen is not supported");
    }
  };
  useEffect(()=>{
    const REACT_APP_IS_DEBUG = localStorage.getItem('REACT_APP_IS_DEBUG')
    if(REACT_APP_IS_DEBUG == undefined) {
      localStorage.setItem('REACT_APP_IS_DEBUG', 'false')
    }
  },[])

  
  const onClickSound = () => {
    toggleSound(!sound)
  };


  const onClickDebug = () => {
    toggleDebug(!debug)
  };

  const onClickOrbit = () => {
    toggleRotation(!rotation)
  };

  const onClickInfo = () => {
    alert('Will available soon')
  };

  const onClickRefresh = () => {
    resetAll()
  };

  useFrame(state => {
    if(!resetting) { return null}
    const vec = new Vector3(INITIAL_CAMERA.position[0], INITIAL_CAMERA.position[1], INITIAL_CAMERA.position[2]);    
    state.camera.position.lerp(vec, 0.1)
    state.camera.updateProjectionMatrix()
  })
  useFrame(state => {
    if(!arrow) { return null}
    let vec;
    switch (arrow) {
      case 'up':
        vec = new Vector3(camera.position.x, camera.position.y - ROTATION_DELTA, camera.position.z);    
        break;
      case 'down':
        vec = new Vector3(camera.position.x, camera.position.y + ROTATION_DELTA, camera.position.z);    
        break;
      case 'right':
        vec = new Vector3(camera.position.x, camera.position.y , camera.position.z - ROTATION_DELTA);    
        break;
      case 'left':
        vec = new Vector3(camera.position.x, camera.position.y , camera.position.z + ROTATION_DELTA);    
        break;
      default:
        break;
    }
    if(!vec) {return}
    camera.position.lerp(vec, 0.1)
    camera.updateProjectionMatrix()
  })

  useEffect(() => {
    document.body.style.cursor = rotation ? 'move' : 'auto'
  }, [rotation])


  return (
    <Html style={matches ? {
      zIndex: ZINDEX,
      top: 'calc(50vh - 220px)',
      right: -size.width/2.1
    } : {
      zIndex: ZINDEX,
      top: `calc(50vh - 80px)`,
      right: -size.width/2.1
    }}>
        
      <Grid flexDirection={matches ? 'column' : 'row'} className={classes.wrap}>
        {isDebugApplicable && debug && <Stats className="global-stats"  />}
      <IconButton className={clsx(classes.button, sound && classes.buttonActive)} onClick={onClickSound}>
         {sound ?  <VolumeUp/>  : <VolumeOff/>} 
      </IconButton>
      <IconButton className={classes.button} onClick={onClickOrbit}>
         <MoveIcon color={rotation ? '#9f854c' : '#fff'}/>
      </IconButton>
      {/* <IconButton className={classes.button} onClick={onClickInfo}>
        <InfoRounded />
      </IconButton> */}
      <IconButton className={classes.button} onClick={onClickRefresh}>
        <Refresh />
      </IconButton>
      <IconButton className={classes.button} onClick={onClickFullScreen}>
        <Fullscreen />
      </IconButton>
      { isDebugApplicable && 
        <IconButton className={clsx(classes.button, debug && classes.buttonActive)} onClick={onClickDebug}>
          <BugReport />
        </IconButton>
      }
      
      
      {debug && isDebugApplicable ? <span className={classes.map} > {map}</span> : null}
    </Grid>
    </Html>
    
  );
}
