


import { museumContextStore } from "@features/Museum/museumContextStore";
import React from "react";
import { POSITIONS, positionsKeys } from "./constants";




type BookThumbnailProps = {
  number: positionsKeys;
  height: number;
  width: number;
  onClick?(n: string):void;
  shown: boolean;
}
const BookThumbnail = ({number, height, width, onClick, shown}: BookThumbnailProps) => {
  const {debug} =  museumContextStore();
  const handleOnclick= ()=>{
    if(onClick) {
      onClick(number)
    }
  }
  if(!shown) {
    return null
  }
  return (
    <span onClick={handleOnclick} style={{
      zIndex: 999,
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      ...POSITIONS[number](height, width),
      background: 'red',
      }} > Book  {number} </span>
  );
};

export default BookThumbnail;