import CookieConsent from 'react-cookie-consent'
import { Box, Typography, Button } from '@mui/material'
import clsx from 'clsx'
import { useStyles } from './styles'

function CookiesPopup() {
  const classes = useStyles()

  function handleCookieConsent(acceptedByScrolling: boolean | undefined) {
    if (acceptedByScrolling) {
      console.log('Its accepted by scrolling')
      return
    }
    console.log('Its accepted by clicking')
  }



  return (
        <Box component={`div`} className={clsx(`${classes.root}`)}>
          {/* TODO : Fix Positioning for the button  */}
          <CookieConsent
            visible={'byCookieValue'}
            disableStyles
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: ' #FFFFFF',
              boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
              padding: '20px 30px',
              position: 'fixed',
              bottom: '0',
              width: '-webkit-fill-available',
            }}
            onAccept={handleCookieConsent}
            expires={3}
            ButtonComponent={Button}
            customButtonProps={{ variant: 'contained' }}
            buttonText="Accept"
          >
            <Typography
              className={clsx(`${classes.title}`)}
              variant={'h5'}
            >
              By using our site you consent to the use of cookies in
              accordance with our cookie policy.
            </Typography>
          </CookieConsent>
        </Box>

  )
}

export default CookiesPopup
