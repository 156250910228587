import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  loader: {
    color: "#fff",
  },
  text: {
    color: "#fff",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});