import { colors, createTheme } from '@mui/material'
import { typography } from './typography'
const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#9F854C',
    },
    secondary: {
      main: '#000',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  components: {
    MuiOutlinedInput:{
      styleOverrides:{
        root: {
          background: "#fff",
          borderRadius: "15px",
        },
        input: {
          padding: '9px 14px',
          color: "#4F4F4F"
        },
        notchedOutline: {
          border: '1px solid #E7E7E7;'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
           background:
            'linear-gradient(90deg, #C6AC73 -13.37%, #9F854C 113.95%)',
        },
        root: {
          borderRadius: 20,
          boShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
  typography,
})

export default theme
