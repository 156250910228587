import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles({
    mainDiv: {
        width: '100vw',
        height: '100vh',
        background: '#FFF',
        position: 'relative',
        overflowY: 'scroll'
    },
    container: {
        paddingTop: "150px",
        paddingBottom: "150px",
        display: 'inline-block'
        
    },
    heading: {
        fontSize: "28px  !important",
        marginBottom: "64px  !important",
        textAlign: "center",
        fontWeight: "600  !important"
    },
    root: {
        margin: "0 auto !important",
       
    },
    loader: {
        color: '#fff !important',
    },
    formGroup: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    },
    title: {
        margin: "0",
        color: "#9F854C  !important",
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "5px",
        marginLeft: "10px !important"
    },
    error: {
        textAlign: "right",
        marginRight: "0",
        color: "#9F854C  !important",
        fontSize: "10px",
        fontWeight: "500"
    },
    color: {
        color: "#9F854C"
    },
    form: {
        marginBottom: "50px",
        flexDirection : 'row', 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection : 'column'
        }
    },
    body1: { fontSize: "17px !important", lineHeight: "26px !important" },
    btnbox: {
        textAlign: "center",
        "& .MuiButton-root": {
            background: "linear-gradient(90deg, #C6AC73 -13.37%, #9F854C 113.95%)",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            padding: "7px 11px",
            borderRadius: " 15px",
            width: "156px",
            height: "36.23px"
        }
    },
    contact_number: {
        background: "red"
    },

});