import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  buttonContainer: {
    fontSize: '70px',
    position: 'fixed !important' as any,
    borderRadius: '0px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  top: {
    color: "#fff !important",
    borderRadius: '0px !important',
    width: 200,
  },
  down: {
    color: "#fff !important",
    borderRadius: '0px !important',
    width: 200,
  },
  left:{
    color: "#fff !important",
    borderRadius: '0px !important',
    height: 200,
  },
  right:{
    color: "#fff !important",
    borderRadius: '0px !important',
    height: 200,
  }
});