import { INITIAL_CAMERA } from '@utils/constants';
import create from 'zustand'
const INITIAL_SCENE = 1; 
const INITIAL_VIDEO_TYPE="INTRODUCTION"

export const useMapStore = create((set: any) => ({
  map:INITIAL_SCENE,
  isDebugApplicable: localStorage.getItem('REACT_APP_IS_DEBUG') == 'true',
  debug: process.env.REACT_APP_IS_DEBUG === "true",
  rotation: true,
  sound: true,
  modal: false,
  resetting: false,
  zoom: INITIAL_CAMERA.zoom,
  toggleModal: (modal: boolean) => set({ modal: modal }),
  introScreenVisibility: true,
  setIntroScreenVisibility: (introScreenVisibility: boolean) => set({ introScreenVisibility }),
  videoType : INITIAL_VIDEO_TYPE,
  arrow: undefined,
  setVideoType : (videoType : string)=>set({videoType }),
  setArrow: (arrow: 'up' | 'right' | 'left' | 'down' | undefined )=>set({arrow}),
  resetAll: () => {
    set({map:INITIAL_SCENE, resetting: true, rotation: false, zoom: INITIAL_CAMERA.zoom, INITIAL_SCENE })
    setTimeout(()=>{
      set({resetting: false})
    },1000)
  },
  toggleDebug: (debug: boolean) => set({ debug: debug }),
  toggleRotation: (rotation: boolean) => set({ rotation: rotation }),
  toggleSound: (sound:boolean) =>  set({ sound: sound }),
  setMap: (m: number) => {
    return set({ map: m })
  },
  setZoom: (z: number) => {
    return set({ zoom: z })
  }
}))
