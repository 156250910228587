import { useRef, useState } from "react";
import * as THREE from "three"
import { useMove } from "@use-gesture/react";
import { useLoader, Vector3 } from "@react-three/fiber";
import { TextureLoader } from "three";
import cursor from './assets/cursor.png'
import {Image} from '@react-three/drei'
type Props = {
  position: any, 
  visible: boolean,
  rotation?: number,
}
export function CursorPlane({position, visible, rotation=0}: Props) {
  const texture = useLoader(TextureLoader, cursor)
  if(!visible) {return null}
  return (
    <mesh position={[position.x, position.y + 0.1 , position.z]} scale={[1, 1, 1]}  rotation={[Math.PI / 2, 0, Math.PI / 2  + rotation]}>
      <planeGeometry args={[5,5]} />
      <meshPhongMaterial transparent attach="material"  map={texture} side={THREE.DoubleSide} />
    </mesh>
  );
}


