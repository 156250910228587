import Header from '@features/360/components/Header/SecondaryHeader'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useStyles } from './styles'
import { validateEmail , validatePhone} from './validators'

function RegistrationPage() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [form, setFrom] = useState(
    {
      email: {value: '', error: false},
      title: {value: '', error: false},
      firstName: {value: '', error: false},
      lastName:{value: '', error: false},
      phone: {value: '', error: false},
      country: {value: '', error: false},
      state: {value: '', error: false},
      agreement:  {value: '', error: false},
    }
  )
  const navigate = useNavigate()

  function handleFormSubmit(_event: any) {
    setLoading(true)
    const f = {
      "email": form.email.value,
      "title": form.title.value,
      "firstName": form.firstName.value,
      "lastName": form.lastName.value,
      "phone": form.phone.value,
      "country": form.country.value,
      "state": form.state.value,
      "agreement": form.agreement.value,
    };

    if (!validateEmail(f.email) || !f.title || !f.firstName ||  !f.lastName || !validatePhone(f.phone) || !f.country || !f.state || !form.agreement.value) {
      setFrom({
        email: {value: form.email.value, error: !validateEmail(form.email.value)},
        title: {value: form.title.value, error: !form.title.value},
        firstName: {value: form.firstName.value, error: !form.firstName.value},
        lastName:{value: form.lastName.value, error: !form.lastName.value},
        phone: {value: form.phone.value, error: !validatePhone(form.phone.value)},
        country: {value: form.country.value, error: !form.country.value},
        state: {value: form.state.value, error: !form.state.value},
        agreement:  {value: form.agreement.value, error: !form.agreement.value},
      })
      setLoading(false)
      return
    }
    console.log(process.env.REACT_APP_API_URL)
    fetch(`${process.env.REACT_APP_API_URL}/api/contacts/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(f),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('Success:', data);
        setLoading(false)
        navigate('/360')
      })
      //Then with the error genereted...
      .catch((error) => {
        alert("Error reaching server, please try in some time")
        setLoading(false)
      });
  }

  function handleSelectChange(event: SelectChangeEvent<string>) {
    const name = event.target.name 
    const value = event.target.value
    console.log(`${name} ::: ${value}`)
    const error = !value
    setFrom({...form, [name]: {error, value: value }})
  }

  function handleCheckChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name 
    const value = !form.agreement.value
    console.log(`${name} ::: ${value}`)
    const error = !value
    setFrom({...form, [name]: {error, value: value }})
  }

  function handleFieldChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name
    const value = event.target.value || ''
    console.log(`${name} ::: ${value}`)
    let error = !value
   setFrom({...form, [name]: {error, value }})
  }
  console.log({form})
  return (
    <>
      <Header />
      <Box component="div" className={clsx([classes.mainDiv])}>
        <Container className={clsx([classes.container])}>
          <Grid container spacing={2}>
            <Grid lg={8} md={10} sm={12} className={clsx([classes.root])} item>
              <Typography
                variant={'h2'}
                className={clsx([classes.heading, 'gradientleft'])}
              >
                Before we start, please fill in next information
              </Typography>
              <Box
                component="form"
                noValidate
                className={clsx([classes.form])}
              >
                <Grid sx={{marginBottom: 0}} margin={2} lg={6} md={6} sm={12} display='flex' flexDirection={'column'} >
                <FormControl sx={{ width: '25ch' }}>
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      Title
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <Select
                    disabled={loading}
                    error={form.title.error}
                    name="title"
                    onChange={handleSelectChange}
                    value={form.title.value}
                  >
                    <MenuItem value={''}>{'Please Select'}</MenuItem>
                    <MenuItem value={'Miss'}>Miss</MenuItem>
                    <MenuItem value={'Ms.'}>Ms.</MenuItem>
                    <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
                    <MenuItem value={'Mr.'}>Mr.</MenuItem>
                  </Select>
                </FormControl>         
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      First name
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <OutlinedInput
                    disabled={loading}
                    error={form.firstName.error}
                    margin='dense'
                    name="firstName"
                    onChange={handleFieldChange}
                    value={form.firstName.value}
                    type="text"
                  />
                </FormControl>
               
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      Last name
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <OutlinedInput
                    disabled={loading}
                    error={form.lastName.error}
                    margin='dense'
                    type="text"
                    name="lastName"
                    onChange={handleFieldChange}
                    value={form.lastName.value}
                  />
                </FormControl>
               
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      Phone Number
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  {/* TODO : Remove number input arrows on side of input  */}
                  <OutlinedInput
                    disabled={loading}
                    error={form.phone.error}
                    margin='dense'
                    type="tel"
                    name="phone"
                    onChange={handleFieldChange}
                    value={form.phone.value}
                  />
                </FormControl>
  
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      Email
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <OutlinedInput
                    disabled={loading}
                    error={form.email.error}
                    margin='dense'
                    name="email"
                    onChange={handleFieldChange}
                    value={form.email.value}
                    type="email"
                  />
                </FormControl>
                </Grid>
                <Grid margin={2} lg={6} md={6} sm={12}  display='flex' flexDirection={'column'} >
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      Country
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <OutlinedInput
                    disabled={loading}
                    error={form.country.error}
                    margin='dense'
                    name="country"
                    onChange={handleFieldChange}
                    value={form.country.value}
                    type="text"
                  />
                </FormControl>
                <FormControl sx={{ width: '100' }} variant="outlined">
                  <Box component={`div`} className={clsx([classes.formGroup])}>
                    <FormLabel className={clsx([classes.title])}>
                      State
                    </FormLabel>
                    <FormHelperText className={clsx([classes.error])}>
                      *Required
                    </FormHelperText>
                  </Box>
                  <OutlinedInput
                    disabled={loading}
                    error={form.state.error}
                    margin='dense'
                    type="text"
                    name="state"
                    onChange={handleFieldChange}
                    value={form.state.value}
                  />
                </FormControl>
                <FormControl
                  sx={{ width: '100' }}
                  variant="outlined"
                  style={{ marginTop: '20px' }}
                >
                  <Typography variant="body1" className={clsx([classes.body1])}>
                    By clicking Enter, you acknowledge you have read and agreed
                    to our{' '}
                    <a target={'_blank'} className={clsx([classes.color])} href='https://www.kkl-jnf.org/terms-of-use/'>
                    {'  '} Terms of Use  {'  '}
                    </a>
                    and
                    <a target={'_blank'} className={clsx([classes.color])} href='https://www.kkl-jnf.org/privacy-policy/'>
                      {'  '}  Privacy Policy.  {'  '}
                    </a>
                  </Typography>
                </FormControl>
                <FormControl  sx={{ width: '100' }} variant="outlined">
                  <FormControlLabel
                    disabled={loading}
                    control={<Checkbox name="agreement" checked={!!form.agreement.value} onChange={handleCheckChange} />}
                    label="Yes, I accept the terms in the License Agreement."
                  />
                 {form.agreement.error &&  <FormHelperText error> Please agree to terms </FormHelperText>}
                </FormControl>
                  </Grid>
              </Box>
              <Box component={`div`} className={clsx([classes.btnbox])}>
                <Button
                  type="button"
                  onClick={handleFormSubmit}
                  variant="contained"
                  disabled={loading}
                >
                  {loading ?  <CircularProgress size={20} className={clsx([classes.loader])} /> : 'Enter'}
                  
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default RegistrationPage