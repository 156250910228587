import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useWindowSize } from "@utils/hooks/useWindowSize";
import React, { useState } from "react";
import FlipBook from "../FlipBookVideo";
import { useStyles } from './styles';


type Props = {
  open: boolean;
  onClose(): void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BookList = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<null | number>(null);
  const { height, width } = useWindowSize()
  const handleClose = () => {
    if (opened) {
      setOpened(null);
    } else {
      onClose();
    }
  };
  if(height && width ){
    let [dialogWidth, dialogHeight ] = [width, height]
    if (width < height) {
      dialogWidth =  width
      dialogHeight =  dialogWidth *0.7
    }
    if (height < width) {
      dialogHeight = height
      dialogWidth = width
    }

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.modal}
      >
        <div className={classes.root} >
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <FlipBook width={dialogWidth} height={dialogHeight} />
          </Grid>
        </div>
        <IconButton
          className="close"
          edge="start"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

    );
  }
  return null;
};
export default BookList;
