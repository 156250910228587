import * as THREE from "three";

import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

import { Pin } from "@features/360/components/Pin";
type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene11({ texture1, texture2 }: Props) {
  return (
    <>
      <Pin position={[115, 1, 3.5]} />
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />
      
      <FloorPlane
        position={[105, GROUND_HEIGHT, -10]}
        args={[24, 24, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[80, GROUND_HEIGHT, 90]}
        args={[24, 24, 24, 24]}
        onClick={8}
      />


      <FloorPlane
        position={[5, GROUND_HEIGHT, 0]}
        args={[34, 34, 24, 24]}
        onClick={11}
      />

      <FloorPlane
        position={[45, GROUND_HEIGHT, -110]}
        args={[24, 24, 24, 24]}
        onClick={12}
      />



    </>
  );
}
