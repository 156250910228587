import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: "99999999 !important",
    width: "100% !important",
    background: "#fff  !important",
  },
  head: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '28px !important',
    lineHeight: '38px',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #C6AC73 -0.98%, #9F854C 53.03%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',

  },
  image: {
    height: 64,
    padding: "0 !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9",
    [theme.breakpoints.down('md')]: {
      left: "auto",
      right: "0%",
      height: 44,
      width: 'min-content',
    },
  
  },
  toolbar: {
    justifyContent: 'space-between',
    zIndex: "99999999",
    // width: "100%",
    boxShadow: "none",
  },
  btn: {
    marginRight: '12px !important',
    marginLeft: '12px !important',
  },
  right: {
    flex: "0 0 25%",
    maxWidth: "50%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ['@media (max-width:1440px)']: { // eslint-disable-line no-useless-computed-key
      flex: "0 0 35%",
    },

    ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
      flex: "0 0 40%",
    }
  }
}));