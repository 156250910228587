import { useMapStore } from '@features/contextStore'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ZoomOut from '@mui/icons-material/ZoomOut'
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { Html } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { MathUtils } from 'three'
import { useStyles } from './styles'

const ZINDEX = 0
type Props = {}
export function ZoomControls({}: Props) {
  const classes = useStyles()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { camera, size} = useThree()
  const { debug, zoom, setZoom } = useMapStore()

  useFrame((state) => {
    state.camera.zoom = MathUtils.lerp(state.camera.zoom, zoom, 0.1)
    state.camera.updateProjectionMatrix()
  })

  const onZoomIn = () => {
    setZoom(camera.zoom + 0.2)
  }

  const onZoomOut = () => {
    setZoom(camera.zoom - 0.2)
  }
  if (debug) {
    console.log({ zoom: camera.zoom })
  }
  return (
    <Html
      style={matches ? {
        zIndex: ZINDEX,
        position: 'fixed',
        top: `calc(50vh - 100px)`,
        right: size.width/4
      } : {
        zIndex: ZINDEX,
        position: 'fixed',
        top: `calc(50vh - 130px)`,
        left: -40,
      }}
    >
      <Grid className={classes.wrap}>
        <IconButton
          disabled={zoom > 4}
          className={classes.button}
          onClick={onZoomIn}
        >
          <ZoomIn />
        </IconButton>
        <IconButton
          disabled={zoom < 0.7}
          className={classes.button}
          onClick={onZoomOut}
        >
          <ZoomOut />
        </IconButton>
      </Grid>
    </Html>
  )
}
