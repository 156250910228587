import * as THREE from "three";

import { Pin } from "@features/360/components/Pin";
import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene6({ texture1, texture2 }: Props) {
  return (
    <>
      <Pin position={[106.2, 1.5, 61.43]} />
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[80, GROUND_HEIGHT, -65]}
        args={[24, 24, 24, 24]}
        onClick={1}
      />

     <FloorPlane
        position={[82, GROUND_HEIGHT, -15]}
        args={[30, 30, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[200, GROUND_HEIGHT, -20]}
        args={[24, 24, 24, 24]}
        onClick={5}
      />

      <FloorPlane
        position={[8, GROUND_HEIGHT, 0]}
        args={[34, 34, 24, 24]}
        onClick={6}
      />


      <FloorPlane
        position={[140, GROUND_HEIGHT, -150]}
        args={[18, 18, 24, 24]}
        onClick={12}
      />
    </>
  );
}
