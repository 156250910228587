import { bookContextStore } from "@features/360/bookContextStore";
import { useMapStore } from "@features/contextStore";
import React, { useEffect, useRef } from "react";
import { BookKeys, BookVideoKeys, VideoKeys, VIDEOS } from "../constants";
import BookTurnClickable from "../elements/BookTurnClickable";


type VideoPlayerProps = {
  width: number;
  height: number;
  src: string;
  onEnd?(m?: BookVideoKeys):  void;
  media: BookVideoKeys;
  loop?: boolean;
  selectedBook: BookKeys
}
const VideoPlayer = ({loop,width=932, height=535, src, media, onEnd, selectedBook}: VideoPlayerProps) => {
  const vidRef = useRef<any>(null);
  const {action, setAction, setTurn, clearAction} = bookContextStore()
  const {sound} = useMapStore();

  const videoKey:VideoKeys = media.split(".")[1] as VideoKeys
  const shown:boolean = String(VIDEOS[selectedBook][videoKey]) === src;

  const onEnded= ()=> {
    if ([`${selectedBook}.cuts_5`, `${selectedBook}.cuts_8`, `${selectedBook}.cuts_14`, `${selectedBook}.cuts_17`, `${selectedBook}.cuts_23`, `${selectedBook}.cuts_26`, `${selectedBook}.cuts_32`, `${selectedBook}.cuts_35`].includes(media)) {
      console.log("in loop")
      return
    }
    if(!onEnd || !shown ){return}
    if(onEnd) {
      if(action === 'NEXT_PAGE') {
        switch (media) {
          case `${selectedBook}.cuts_6`:
            onEnd(`${selectedBook}.cuts_11`)
            break;
          case `${selectedBook}.cuts_15`:
            onEnd(`${selectedBook}.cuts_20`)
            break;
          case `${selectedBook}.cuts_24`:
            onEnd(`${selectedBook}.cuts_29`)
            break;
          case `${selectedBook}.cuts_33`:
            onEnd(`${selectedBook}.cuts_37`)
            break;
          default:
            onEnd()
            break;
        }
        clearAction()
        return
      }
      if(action === 'TOGGLE_PAGE') {
        switch (media) {
          case `${selectedBook}.cuts_9`:
            onEnd(`${selectedBook}.cuts_4`)
            break;
          case `${selectedBook}.cuts_18`:
            onEnd(`${selectedBook}.cuts_13`)
            break;
          case `${selectedBook}.cuts_27`:
            onEnd(`${selectedBook}.cuts_22`)
            break;
          case `${selectedBook}.cuts_36`:
            onEnd(`${selectedBook}.cuts_31`)
            break;
          default:
            onEnd()
            break;
        }
        clearAction()
        return
      }
      onEnd()
    }
  }

  useEffect(()=>{
    if(shown) {
      vidRef.current?.play?.();
      vidRef.current.style.opacity = 1
      vidRef.current.style.width = width
      vidRef.current.style.height = height
    }else{
      vidRef.current?.load();
      vidRef.current.style.opacity = 0;
      vidRef.current.style.width = width
      vidRef.current.style.height = height
    }

  },[shown])

  const onClickNext =()=> {
    setTurn()
    if(onEnd) {
      onEnd()
    }
  }

  const onClickAction =()=> {
    setAction()
    if(onEnd) {
      onEnd()
    }
  }
  return (
      <>
        <video
          loop={loop}
          style={{opacity: 0, position: 'absolute'}}
          muted={sound}
          controls={false}
          ref={vidRef}
          src={src}
          width={width}
          height={height}
          playsInline
          onEnded={loop ? undefined : onEnded}
        />
        <BookTurnClickable  onClickAction={onClickAction} onClickNext={onClickNext} shown={shown && (loop === true)}  height={height}  width={width} />
      </>
  );
};

export default VideoPlayer;