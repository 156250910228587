import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";
import * as THREE from "three";


type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene9({ texture1, texture2 }: Props) {
  return (
    <>

      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[165, GROUND_HEIGHT, 25]}
        args={[18, 18, 24, 24]}
        onClick={1}
      />

      <FloorPlane
        position={[140, GROUND_HEIGHT, -4]}
        args={[24, 24, 24, 24]}
        onClick={2}
      />

   

      <FloorPlane
        position={[35, GROUND_HEIGHT, -120]}
        args={[34, 34, 24, 24]}
        onClick={8}
      />

      <FloorPlane
        position={[12, GROUND_HEIGHT, -2]}
        args={[34, 34, 24, 24]}
        onClick={9}
      />


    </>
  );
}
