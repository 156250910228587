import * as THREE from "three";

import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

import { Pin } from "@features/360/components/Pin";
import { ExternalButtons } from "@features/360/components/ExternalButtons";
type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene2({ texture1, texture2 }: Props) {
  return (
    <>
      <Pin position={[115, 1, 3.5]} />
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[-48, GROUND_HEIGHT, 0]}
        args={[34, 34, 24, 24]}
        onClick={1}
      />

      <FloorPlane
        position={[8, GROUND_HEIGHT, -2]}
        args={[28, 28, 24, 24]}
        onClick={2}
      />

      <FloorPlane
        position={[190, GROUND_HEIGHT, -2]}
        args={[28, 28, 24, 24]}
        onClick={3}
      />

      <FloorPlane
        position={[-5, GROUND_HEIGHT, -105]}
        args={[29, 29, 24, 24]}
        onClick={5}
      />


      <FloorPlane
        position={[20, GROUND_HEIGHT, 80]}
        args={[34, 34, 24, 24]}
        onClick={6}
      />

      <FloorPlane
        position={[120, GROUND_HEIGHT, 100]}
        args={[24, 24, 24, 24]}
        onClick={7}
      />



      <FloorPlane
        position={[-10, GROUND_HEIGHT, -150]}
        args={[24, 24, 24, 24]}
        onClick={8}
      />


      <FloorPlane
        position={[120, GROUND_HEIGHT, -100]}
        args={[24, 24, 24, 24]}
        onClick={9}
      />

      <FloorPlane
        position={[55, GROUND_HEIGHT, 120]}
        args={[18, 18, 24, 24]}
        onClick={10}
      />

      <FloorPlane
        position={[-90, GROUND_HEIGHT, 90]}
        args={[28, 28, 24, 24]}
        onClick={11}
      />


      <FloorPlane
        position={[-130, GROUND_HEIGHT, -48]}
        args={[34, 34, 24, 24]}
        onClick={12}
      />
    </>
  );
}
