import * as THREE from "three";

import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene8({ texture1, texture2 }: Props) {
  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />


      <FloorPlane
        position={[50, GROUND_HEIGHT, -35]}
        args={[24, 24, 24, 24]}
        onClick={5}
      />


      <FloorPlane
        position={[15, GROUND_HEIGHT, 0]}
        args={[30, 30, 24, 24]}
        onClick={8}
      />


      <FloorPlane
        position={[-62, GROUND_HEIGHT, -110]}
        args={[34, 34, 24, 24]}
        onClick={9}
      />


      <FloorPlane
        position={[180, GROUND_HEIGHT, -10]}
        args={[24, 24, 24, 24]}
        onClick={12}
      />


      <FloorPlane
        position={[130, GROUND_HEIGHT, -100]}
        args={[24, 24, 24, 24]}
        onClick={2}
      />
    </>
  );
}
