import { useMapStore } from "@features/contextStore";
import { Vector3 } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { DoubleSide } from "three";

type Props = {
  position: Vector3;
  isMovable?: boolean;
  type: "ARCHIVE" | "DONATE" | "VISIT_MUSEUM";
  size?: number
};
export function ExternalButtons({ type, position, isMovable, size=8 }: Props) {
  const mesh = useRef<any>(null!);
  const { debug, rotation, setIntroScreenVisibility, setVideoType, videoType } = useMapStore();
  
  useEffect(() => {
    if (!isMovable) {
      return;
    }
    const handleKeyDown = (_e: any) => {
      switch (_e.key) {
        case "a":
          mesh.current.position.x += 0.1;
          console.log(mesh.current.position);
          break;
        case "z":
          mesh.current.position.x -= 0.1;
          console.log(mesh.current.position);
          break;
        case "ArrowLeft":
          mesh.current.position.z -= 0.1;
          console.log(mesh.current.position);
          break;
        case "ArrowRight":
          mesh.current.position.z += 0.1;
          console.log(mesh.current.position);
          break;
        case "ArrowUp":
          mesh.current.position.y += 0.1;
          console.log(mesh.current.position);
          break;
        case "ArrowDown":
          mesh.current.position.y -= 0.1;
          console.log(mesh.current.position);
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isMovable]);

  const [hovered, hover] = useState(false);
  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : rotation ? 'move' : 'auto';
  }, [hovered]);

  
  const handleClick = () => {
    if (type === 'ARCHIVE') {
      window.open(`https://booksofhonorarchive.kkl.org.il`, '_blank');
      return
    }

    if (type === 'DONATE') {
      window.open(`https://booksofhonor.kkl.org.il/#donate`, '_blank');
      // setVideoType("DONATION")
      // setIntroScreenVisibility(true)
      return
    }

    if (type === "VISIT_MUSEUM") {
      setVideoType("VISIT_MUSEUM");
      setIntroScreenVisibility(true);
      return;
    }
  }

  return (
    <mesh
      onClick={handleClick}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
      ref={mesh}
      position={position}
      rotation={[0, Math.PI / 2, Math.PI / 2]}
      scale={[1, 1, 1]}
    >
      <planeGeometry args={[size, size, 5, 5]} />
      <meshPhongMaterial transparent={!debug} opacity={debug ? 0.8 : 0 } wireframe={debug  ?  !hovered : false} color="green" side={DoubleSide} />
    </mesh>
  );
}
