import * as THREE from "three";
import { FloorPlane } from "@features/360/components/Floor";
import { GROUND_HEIGHT } from "@utils/constants";
import { ExternalButtons } from "@features/360/components/ExternalButtons";

type Props = {
  texture1: THREE.Texture;
  texture2: THREE.Texture;
};
export function Scene3({ texture1, texture2 }: Props) {
  return (
    <>
      <meshBasicMaterial
        attach="material"
        map={texture1}
        side={THREE.DoubleSide}
      />

      <FloorPlane
        position={[-200, GROUND_HEIGHT, 0]}
        args={[24, 24, 24, 24]}
        rotation={Math.PI}
        onClick={1}
      />

      <FloorPlane
        position={[-157, GROUND_HEIGHT, 3]}
        args={[24, 24, 24, 24]}
        rotation={Math.PI}
        onClick={2}
      />

      <FloorPlane
        position={[12, GROUND_HEIGHT, 2]}
        args={[28, 28, 24, 24]}
        rotation={Math.PI}
        onClick={3}
      />

      <FloorPlane
        position={[230, GROUND_HEIGHT, 0]}
        args={[36, 36, 24, 24]}
        onClick={4}
      />

      <FloorPlane
        position={[-150, GROUND_HEIGHT, 60]}
        args={[24, 24, 24, 24]}
        rotation={Math.PI}
        onClick={6}
      />

      <FloorPlane
        position={[-50, GROUND_HEIGHT, 100]}
        args={[24, 24, 24, 24]}
        rotation={Math.PI}
        onClick={7}
      />


      <FloorPlane
        position={[-45, GROUND_HEIGHT, -100]}
        args={[34, 34, 24, 24]}
        rotation={Math.PI}
        onClick={9}
      />

      <FloorPlane
        position={[-110, GROUND_HEIGHT, 120]}
        args={[34, 34, 24, 24]}
        rotation={Math.PI}
        onClick={10}
      />


    </>
  );
}
