export const VIDEOS = {
  book1: {
    cuts_1: '/360/videos/book1/book1_cut1.mp4',
    cuts_2: '/360/videos/book1/book1_cut2.mp4',
    cuts_3: '/360/videos/book1/book1_cut3.mp4',
    cuts_4: '/360/videos/book1/book1_cut4.mp4',
    cuts_5: '/360/videos/book1/book1_cut5.mp4',
    cuts_6: '/360/videos/book1/book1_cut6.mp4',
    cuts_7: '/360/videos/book1/book1_cut7.mp4',
    cuts_8: '/360/videos/book1/book1_cut8.mp4',
    cuts_9: '/360/videos/book1/book1_cut9.mp4',
    cuts_10: '/360/videos/book1/book1_cut10.mp4',
    cuts_11: '/360/videos/book1/book1_cut11.mp4',
    cuts_12: '/360/videos/book1/book1_cut12.mp4',
    cuts_13: '/360/videos/book1/book1_cut13.mp4',
    cuts_14: '/360/videos/book1/book1_cut14.mp4',
    cuts_15: '/360/videos/book1/book1_cut15.mp4',
    cuts_16: '/360/videos/book1/book1_cut16.mp4',
    cuts_17: '/360/videos/book1/book1_cut17.mp4',
    cuts_18: '/360/videos/book1/book1_cut18.mp4',
    cuts_19: '/360/videos/book1/book1_cut19.mp4',
    cuts_20: '/360/videos/book1/book1_cut20.mp4',
    cuts_21: '/360/videos/book1/book1_cut21.mp4',
    cuts_22: '/360/videos/book1/book1_cut22.mp4',
    cuts_23: '/360/videos/book1/book1_cut23.mp4',
    cuts_24: '/360/videos/book1/book1_cut24.mp4',
    cuts_25: '/360/videos/book1/book1_cut25.mp4',
    cuts_26: '/360/videos/book1/book1_cut26.mp4',
    cuts_27: '/360/videos/book1/book1_cut27.mp4',
    cuts_28: '/360/videos/book1/book1_cut28.mp4',
    cuts_29: '/360/videos/book1/book1_cut29.mp4',
    cuts_30: '/360/videos/book1/book1_cut30.mp4',
    cuts_31: '/360/videos/book1/book1_cut31.mp4',
    cuts_32: '/360/videos/book1/book1_cut32.mp4',
    cuts_33: '/360/videos/book1/book1_cut33.mp4',
    cuts_34: '/360/videos/book1/book1_cut34.mp4',
    cuts_35: '/360/videos/book1/book1_cut35.mp4',
    cuts_36: '/360/videos/book1/book1_cut36.mp4',
    cuts_37: '/360/videos/book1/book1_cut37.mp4',
  },
  book2: {
    cuts_1: '/360/videos/book2/book2_cut1.mp4',
    cuts_2: '/360/videos/book2/book2_cut2.mp4',
    cuts_3: '/360/videos/book2/book2_cut3.mp4',
    cuts_4: '/360/videos/book2/book2_cut4.mp4',
    cuts_5: '/360/videos/book2/book2_cut5.mp4',
    cuts_6: '/360/videos/book2/book2_cut6.mp4',
    cuts_7: '/360/videos/book2/book2_cut7.mp4',
    cuts_8: '/360/videos/book2/book2_cut8.mp4',
    cuts_9: '/360/videos/book2/book2_cut9.mp4',
    cuts_10: '/360/videos/book2/book2_cut10.mp4',
    cuts_11: '/360/videos/book2/book2_cut11.mp4',
    cuts_12: '/360/videos/book2/book2_cut12.mp4',
    cuts_13: '/360/videos/book2/book2_cut13.mp4',
    cuts_14: '/360/videos/book2/book2_cut14.mp4',
    cuts_15: '/360/videos/book2/book2_cut15.mp4',
    cuts_16: '/360/videos/book2/book2_cut16.mp4',
    cuts_17: '/360/videos/book2/book2_cut17.mp4',
    cuts_18: '/360/videos/book2/book2_cut18.mp4',
    cuts_19: '/360/videos/book2/book2_cut19.mp4',
    cuts_20: '/360/videos/book2/book2_cut20.mp4',
    cuts_21: '/360/videos/book2/book2_cut21.mp4',
    cuts_22: '/360/videos/book2/book2_cut22.mp4',
    cuts_23: '/360/videos/book2/book2_cut23.mp4',
    cuts_24: '/360/videos/book2/book2_cut24.mp4',
    cuts_25: '/360/videos/book2/book2_cut25.mp4',
    cuts_26: '/360/videos/book2/book2_cut26.mp4',
    cuts_27: '/360/videos/book2/book2_cut27.mp4',
    cuts_28: '/360/videos/book2/book2_cut28.mp4',
    cuts_29: '/360/videos/book2/book2_cut29.mp4',
    cuts_30: '/360/videos/book2/book2_cut30.mp4',
    cuts_31: '/360/videos/book2/book2_cut31.mp4',
    cuts_32: '/360/videos/book2/book2_cut32.mp4',
    cuts_33: '/360/videos/book2/book2_cut33.mp4',
    cuts_34: '/360/videos/book2/book2_cut34.mp4',
    cuts_35: '/360/videos/book2/book2_cut35.mp4',
    cuts_36: '/360/videos/book2/book2_cut36.mp4',
    cuts_37: '/360/videos/book2/book2_cut37.mp4',
  },
  book4: {
    cuts_1: '/360/videos/book4/book4_cut1.mp4',
    cuts_2: '/360/videos/book4/book4_cut2.mp4',
    cuts_3: '/360/videos/book4/book4_cut3.mp4',
    cuts_4: '/360/videos/book4/book4_cut4.mp4',
    cuts_5: '/360/videos/book4/book4_cut5.mp4',
    cuts_6: '/360/videos/book4/book4_cut6.mp4',
    cuts_7: '/360/videos/book4/book4_cut7.mp4',
    cuts_8: '/360/videos/book4/book4_cut8.mp4',
    cuts_9: '/360/videos/book4/book4_cut9.mp4',
    cuts_10: '/360/videos/book4/book4_cut10.mp4',
    cuts_11: '/360/videos/book4/book4_cut11.mp4',
    cuts_12: '/360/videos/book4/book4_cut12.mp4',
    cuts_13: '/360/videos/book4/book4_cut13.mp4',
    cuts_14: '/360/videos/book4/book4_cut14.mp4',
    cuts_15: '/360/videos/book4/book4_cut15.mp4',
    cuts_16: '/360/videos/book4/book4_cut16.mp4',
    cuts_17: '/360/videos/book4/book4_cut17.mp4',
    cuts_18: '/360/videos/book4/book4_cut18.mp4',
    cuts_19: '/360/videos/book4/book4_cut19.mp4',
    cuts_20: '/360/videos/book4/book4_cut20.mp4',
    cuts_21: '/360/videos/book4/book4_cut21.mp4',
    cuts_22: '/360/videos/book4/book4_cut22.mp4',
    cuts_23: '/360/videos/book4/book4_cut23.mp4',
    cuts_24: '/360/videos/book4/book4_cut24.mp4',
    cuts_25: '/360/videos/book4/book4_cut25.mp4',
    cuts_26: '/360/videos/book4/book4_cut26.mp4',
    cuts_27: '/360/videos/book4/book4_cut27.mp4',
    cuts_28: '/360/videos/book4/book4_cut28.mp4',
    cuts_29: '/360/videos/book4/book4_cut29.mp4',
    cuts_30: '/360/videos/book4/book4_cut30.mp4',
    cuts_31: '/360/videos/book4/book4_cut31.mp4',
    cuts_32: '/360/videos/book4/book4_cut32.mp4',
    cuts_33: '/360/videos/book4/book4_cut33.mp4',
    cuts_34: '/360/videos/book4/book4_cut34.mp4',
    cuts_35: '/360/videos/book4/book4_cut35.mp4',
    cuts_36: '/360/videos/book4/book4_cut36.mp4',
    cuts_37: '/360/videos/book4/book4_cut37.mp4',
  },
  book5: {
    cuts_1: '/360/videos/book5/book5_cut1.mp4',
    cuts_2: '/360/videos/book5/book5_cut2.mp4',
    cuts_3: '/360/videos/book5/book5_cut3.mp4',
    cuts_4: '/360/videos/book5/book5_cut4.mp4',
    cuts_5: '/360/videos/book5/book5_cut5.mp4',
    cuts_6: '/360/videos/book5/book5_cut6.mp4',
    cuts_7: '/360/videos/book5/book5_cut7.mp4',
    cuts_8: '/360/videos/book5/book5_cut8.mp4',
    cuts_9: '/360/videos/book5/book5_cut9.mp4',
    cuts_10: '/360/videos/book5/book5_cut10.mp4',
    cuts_11: '/360/videos/book5/book5_cut11.mp4',
    cuts_12: '/360/videos/book5/book5_cut12.mp4',
    cuts_13: '/360/videos/book5/book5_cut13.mp4',
    cuts_14: '/360/videos/book5/book5_cut14.mp4',
    cuts_15: '/360/videos/book5/book5_cut15.mp4',
    cuts_16: '/360/videos/book5/book5_cut16.mp4',
    cuts_17: '/360/videos/book5/book5_cut17.mp4',
    cuts_18: '/360/videos/book5/book5_cut18.mp4',
    cuts_19: '/360/videos/book5/book5_cut19.mp4',
    cuts_20: '/360/videos/book5/book5_cut20.mp4',
    cuts_21: '/360/videos/book5/book5_cut21.mp4',
    cuts_22: '/360/videos/book5/book5_cut22.mp4',
    cuts_23: '/360/videos/book5/book5_cut23.mp4',
    cuts_24: '/360/videos/book5/book5_cut24.mp4',
    cuts_25: '/360/videos/book5/book5_cut25.mp4',
    cuts_26: '/360/videos/book5/book5_cut26.mp4',
    cuts_27: '/360/videos/book5/book5_cut27.mp4',
    cuts_28: '/360/videos/book5/book5_cut28.mp4',
    cuts_29: '/360/videos/book5/book5_cut29.mp4',
    cuts_30: '/360/videos/book5/book5_cut30.mp4',
    cuts_31: '/360/videos/book5/book5_cut31.mp4',
    cuts_32: '/360/videos/book5/book5_cut32.mp4',
    cuts_33: '/360/videos/book5/book5_cut33.mp4',
    cuts_34: '/360/videos/book5/book5_cut34.mp4',
    cuts_35: '/360/videos/book5/book5_cut35.mp4',
    cuts_36: '/360/videos/book5/book5_cut36.mp4',
    cuts_37: '/360/videos/book5/book5_cut37.mp4',
  },
  book6: {
    cuts_1: '/360/videos/book6/book6_cut1.mp4',
    cuts_2: '/360/videos/book6/book6_cut2.mp4',
    cuts_3: '/360/videos/book6/book6_cut3.mp4',
    cuts_4: '/360/videos/book6/book6_cut4.mp4',
    cuts_5: '/360/videos/book6/book6_cut5.mp4',
    cuts_6: '/360/videos/book6/book6_cut6.mp4',
    cuts_7: '/360/videos/book6/book6_cut7.mp4',
    cuts_8: '/360/videos/book6/book6_cut8.mp4',
    cuts_9: '/360/videos/book6/book6_cut9.mp4',
    cuts_10: '/360/videos/book6/book6_cut10.mp4',
    cuts_11: '/360/videos/book6/book6_cut11.mp4',
    cuts_12: '/360/videos/book6/book6_cut12.mp4',
    cuts_13: '/360/videos/book6/book6_cut13.mp4',
    cuts_14: '/360/videos/book6/book6_cut14.mp4',
    cuts_15: '/360/videos/book6/book6_cut15.mp4',
    cuts_16: '/360/videos/book6/book6_cut16.mp4',
    cuts_17: '/360/videos/book6/book6_cut17.mp4',
    cuts_18: '/360/videos/book6/book6_cut18.mp4',
    cuts_19: '/360/videos/book6/book6_cut19.mp4',
    cuts_20: '/360/videos/book6/book6_cut20.mp4',
    cuts_21: '/360/videos/book6/book6_cut21.mp4',
    cuts_22: '/360/videos/book6/book6_cut22.mp4',
    cuts_23: '/360/videos/book6/book6_cut23.mp4',
    cuts_24: '/360/videos/book6/book6_cut24.mp4',
    cuts_25: '/360/videos/book6/book6_cut25.mp4',
    cuts_26: '/360/videos/book6/book6_cut26.mp4',
    cuts_27: '/360/videos/book6/book6_cut27.mp4',
    cuts_28: '/360/videos/book6/book6_cut28.mp4',
    cuts_29: '/360/videos/book6/book6_cut29.mp4',
    cuts_30: '/360/videos/book6/book6_cut30.mp4',
    cuts_31: '/360/videos/book6/book6_cut31.mp4',
    cuts_32: '/360/videos/book6/book6_cut32.mp4',
    cuts_33: '/360/videos/book6/book6_cut33.mp4',
    cuts_34: '/360/videos/book6/book6_cut34.mp4',
    cuts_35: '/360/videos/book6/book6_cut35.mp4',
    cuts_36: '/360/videos/book6/book6_cut36.mp4',
    cuts_37: '/360/videos/book6/book6_cut37.mp4',
  },
  book10: {
    cuts_1: '/360/videos/book10/book10_cut1.mp4',
    cuts_2: '/360/videos/book10/book10_cut2.mp4',
    cuts_3: '/360/videos/book10/book10_cut3.mp4',
    cuts_4: '/360/videos/book10/book10_cut4.mp4',
    cuts_5: '/360/videos/book10/book10_cut5.mp4',
    cuts_6: '/360/videos/book10/book10_cut6.mp4',
    cuts_7: '/360/videos/book10/book10_cut7.mp4',
    cuts_8: '/360/videos/book10/book10_cut8.mp4',
    cuts_9: '/360/videos/book10/book10_cut9.mp4',
    cuts_10: '/360/videos/book10/book10_cut10.mp4',
    cuts_11: '/360/videos/book10/book10_cut11.mp4',
    cuts_12: '/360/videos/book10/book10_cut12.mp4',
    cuts_13: '/360/videos/book10/book10_cut13.mp4',
    cuts_14: '/360/videos/book10/book10_cut14.mp4',
    cuts_15: '/360/videos/book10/book10_cut15.mp4',
    cuts_16: '/360/videos/book10/book10_cut16.mp4',
    cuts_17: '/360/videos/book10/book10_cut17.mp4',
    cuts_18: '/360/videos/book10/book10_cut18.mp4',
    cuts_19: '/360/videos/book10/book10_cut19.mp4',
    cuts_20: '/360/videos/book10/book10_cut20.mp4',
    cuts_21: '/360/videos/book10/book10_cut21.mp4',
    cuts_22: '/360/videos/book10/book10_cut22.mp4',
    cuts_23: '/360/videos/book10/book10_cut23.mp4',
    cuts_24: '/360/videos/book10/book10_cut24.mp4',
    cuts_25: '/360/videos/book10/book10_cut25.mp4',
    cuts_26: '/360/videos/book10/book10_cut26.mp4',
    cuts_27: '/360/videos/book10/book10_cut27.mp4',
    cuts_28: '/360/videos/book10/book10_cut28.mp4',
    cuts_29: '/360/videos/book10/book10_cut29.mp4',
    cuts_30: '/360/videos/book10/book10_cut30.mp4',
    cuts_31: '/360/videos/book10/book10_cut31.mp4',
    cuts_32: '/360/videos/book10/book10_cut32.mp4',
    cuts_33: '/360/videos/book10/book10_cut33.mp4',
    cuts_34: '/360/videos/book10/book10_cut34.mp4',
    cuts_35: '/360/videos/book10/book10_cut35.mp4',
    cuts_36: '/360/videos/book10/book10_cut36.mp4',
    cuts_37: '/360/videos/book10/book10_cut37.mp4',
  },
}

export type BookKeys = keyof typeof VIDEOS
export  type VideoKeys = keyof typeof VIDEOS.book1
export type BookVideoKeys = `${BookKeys}.${VideoKeys}`
