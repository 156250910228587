import { useMapStore } from "@features/contextStore";
import React from "react";

type BookMenuProps = {
  height: number;
  width: number;
  onClickMenu?():void;
}
const BookMenu = ({height, width, onClickMenu}: BookMenuProps) => {
  const {debug} = useMapStore();
  const handleOnclick= ()=>{
    if(onClickMenu) {
      onClickMenu()
    }
  }
  const aspectRatio  = width/height

  return (
    <span onClick={handleOnclick} style={{
      cursor: 'pointer',
      opacity: debug ? 0.4 : 0,
      position:'absolute',
      top: aspectRatio > 2 ? 10 : 20 ,
      left: aspectRatio > 2 ? 80 : 0 ,
      background: 'red', width: width/16, height: height/10
      }} >  </span>
  );
};

export default BookMenu;