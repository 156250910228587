import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrap: {
    zIndex: 999, position: 'fixed', top: 20, right: 50,
    background: "transparent !important",
    display: 'flex',
    borderRadius: '32px',
    backgroundColor: 'rgb(159 133 76 / 57%)', 
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px',
   },
  button:{
    color: 'transparent !important',
  },
  buttonActive: {
    color: '#d9c14b !important'
  },

});