import { ArrowButton } from '@features/360/components/ArrowButton'
import BookList from '@features/Books/components/BookListVideo'
import { OrbitControls } from '@react-three/drei'
import { Canvas, useThree } from '@react-three/fiber'
import { INITIAL_CAMERA, ROTATION_DELTA } from '@utils/constants'
import React, { Suspense, useEffect } from 'react'
import { useMapStore } from '../../contextStore'
import CookiesPopup from '../components/CookiesPopup'
import { Loader } from '../components/Loader'
import { Toolbar } from '../components/Toolbar'
import { ZoomControls } from '../components/ZoomControls'
import IntroVideo from '../containers/IntroVideo'
import { Scenes } from '../Scenes'
import { Vector3 } from "three";


function Controls(props: any) {
  const { camera } = useThree()
  const { rotation, setArrow } = useMapStore()

  useEffect(() => {
    const handleKeyDown = (_e: any) => {
      switch (_e.key) {
        case 'ArrowLeft':
          setArrow('left')
          break
        case 'ArrowRight':
          setArrow('right')
          break
        case 'ArrowUp':
          setArrow('up')
          break
        case 'ArrowDown':
          setArrow('down')
          break
      }
    }
    const handleKeyUp = () => {
      setArrow(undefined)
    }
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    }
  }, [])

  return (
    <>
      <ZoomControls />
      <ArrowButton type="up" />
      <ArrowButton type="right" />
      <ArrowButton  type="down" />
      <ArrowButton  type="left" />
      <OrbitControls
        enableRotate={rotation}
        enablePan={false}
        enableZoom={false}
        enableDamping
        dampingFactor={0.2}
        {...props}
      />
    </>
  )
}

const ThreeSixtyApp = () => {
  const { introScreenVisibility, setIntroScreenVisibility, modal, toggleModal, videoType } = useMapStore()

  if (introScreenVisibility) {
    return (
        <IntroVideo isIntroVideoVisible={introScreenVisibility} setIsIntroVideoVisible={setIntroScreenVisibility} videoType={videoType} />
      )
  }

  return (
    <>
       <Canvas camera={INITIAL_CAMERA}>
        <Controls />
        <Suspense fallback={<Loader />}>
          <Scenes />
        </Suspense>
        <Toolbar />
      </Canvas>
      <BookList open={modal} onClose={() => toggleModal(!modal)} />
    </>
  )
}

export default ThreeSixtyApp
