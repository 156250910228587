import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import { Html, useAspect } from '@react-three/drei'
import { useStyles } from './styles'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import clsx from 'clsx'
import { useThree } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import { useMapStore } from '@features/contextStore'
const ZINDEX = 0
let intervalId: any = null
type Props = {
  type: 'up' | 'down' | 'left' | 'right'
}
export function ArrowButton({type }: Props) {
  const classes = useStyles()
  const { size } = useThree()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {arrow, setArrow} = useMapStore();
  const onPointerRelease = (e: any) => {
    setArrow(undefined)
  }

  const onPointerDown = (e: any) => {
    setArrow(type)
  }

  if (type === 'up') {
    return (
      <Html
        style={{
          zIndex: ZINDEX,
          top: -size.height / 2,
          left: -size.width / 2,
        }}
      >
        <div className={classes.buttonContainer} style={{ width: size.width }}>
          <IconButton
            className={classes.top}
            onPointerOut={onPointerRelease}
            onPointerLeave={onPointerRelease}
            onPointerDown={onPointerDown}
            onLostPointerCapture={onPointerRelease}
          >
            <KeyboardArrowUp />
          </IconButton>
        </div>
      </Html>
    )
  }

  if (type === 'down') {
    return (
      <Html
        style={matches ? { 
          zIndex: ZINDEX,
          top: `calc(50vh - 90px)`,
          left: -size.width / 2,
        } : {
          zIndex: ZINDEX,
          top: `calc(50vh - 60px)`,
          left: -size.width / 2,
        }}
      >
        <div className={classes.buttonContainer} style={{ width: size.width }}>
          <IconButton
            className={classes.down}
            onPointerOut={onPointerRelease}
            onPointerLeave={onPointerRelease}
            onPointerDown={onPointerDown}
            onLostPointerCapture={onPointerRelease}
          >
            <KeyboardArrowDown />
          </IconButton>
        </div>
      </Html>
    )
  }

  if (type === 'left') {
    return (
      <Html
        style={{
          zIndex: ZINDEX,
          right: size.width / 2,
          top: -size.height / 2,
        }}
      >
        <div
          className={classes.buttonContainer}
          style={{ height: size.height }}
        >
          <IconButton
            className={classes.left}
            onPointerOut={onPointerRelease}
            onPointerLeave={onPointerRelease}
            onPointerDown={onPointerDown}
            onLostPointerCapture={onPointerRelease}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </div>
      </Html>
    )
  }

  if (type === 'right') {
    return (
      <Html
        as="div"
        style={{
          zIndex: ZINDEX,
          left:  `calc(50vw - 40px)`,   
          bottom: size.height / 2,
        }}
      >
        <div
          className={classes.buttonContainer}
          style={{ height: size.height }}
        >
          <IconButton
            className={classes.right}
            onPointerOut={onPointerRelease}
            onPointerLeave={onPointerRelease}
            onPointerDown={onPointerDown}
            onLostPointerCapture={onPointerRelease}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </Html>
    )
  }

  return null
}
