import { IS_FULLSCREEN_VIDEOS } from "@utils/constants"

export const VIDEOS_HALF = {
  book1: {
    cuts_1: '/museum/videos/half/book1/book1_cut1.mp4',
    cuts_2: '/museum/videos/half/book1/book1_cut2.mp4',
    cuts_3: '/museum/videos/half/book1/book1_cut3.mp4',
    cuts_4: '/museum/videos/half/book1/book1_cut4.mp4',
    cuts_5: '/museum/videos/half/book1/book1_cut5.mp4',
    cuts_6: '/museum/videos/half/book1/book1_cut6.mp4',
    cuts_7: '/museum/videos/half/book1/book1_cut7.mp4',
    cuts_8: '/museum/videos/half/book1/book1_cut8.mp4',
    cuts_9: '/museum/videos/half/book1/book1_cut9.mp4',
    cuts_10: '/museum/videos/half/book1/book1_cut10.mp4',
    cuts_11: '/museum/videos/half/book1/book1_cut11.mp4',
    cuts_12: '/museum/videos/half/book1/book1_cut12.mp4',
    cuts_13: '/museum/videos/half/book1/book1_cut13.mp4',
    cuts_14: '/museum/videos/half/book1/book1_cut14.mp4',
    cuts_15: '/museum/videos/half/book1/book1_cut15.mp4',
    cuts_16: '/museum/videos/half/book1/book1_cut16.mp4',
    cuts_17: '/museum/videos/half/book1/book1_cut17.mp4',
    cuts_18: '/museum/videos/half/book1/book1_cut18.mp4',
    cuts_19: '/museum/videos/half/book1/book1_cut19.mp4',
    cuts_20: '/museum/videos/half/book1/book1_cut20.mp4',
    cuts_21: '/museum/videos/half/book1/book1_cut21.mp4',
    cuts_22: '/museum/videos/half/book1/book1_cut22.mp4',
    cuts_23: '/museum/videos/half/book1/book1_cut23.mp4',
    cuts_24: '/museum/videos/half/book1/book1_cut24.mp4',
    cuts_25: '/museum/videos/half/book1/book1_cut25.mp4',
    cuts_26: '/museum/videos/half/book1/book1_cut26.mp4',
    cuts_27: '/museum/videos/half/book1/book1_cut27.mp4',
    cuts_28: '/museum/videos/half/book1/book1_cut28.mp4',
    cuts_29: '/museum/videos/half/book1/book1_cut29.mp4',
    cuts_30: '/museum/videos/half/book1/book1_cut30.mp4',
    cuts_31: '/museum/videos/half/book1/book1_cut31.mp4',
    cuts_32: '/museum/videos/half/book1/book1_cut32.mp4',
    cuts_33: '/museum/videos/half/book1/book1_cut33.mp4',
    cuts_34: '/museum/videos/half/book1/book1_cut34.mp4',
    cuts_35: '/museum/videos/half/book1/book1_cut35.mp4',
    cuts_36: '/museum/videos/half/book1/book1_cut36.mp4',
    cuts_37: '/museum/videos/half/book1/book1_cut37.mp4',
  },
  book2: {
    cuts_1: '/museum/videos/half/book2/book2_cut1.mp4',
    cuts_2: '/museum/videos/half/book2/book2_cut2.mp4',
    cuts_3: '/museum/videos/half/book2/book2_cut3.mp4',
    cuts_4: '/museum/videos/half/book2/book2_cut4.mp4',
    cuts_5: '/museum/videos/half/book2/book2_cut5.mp4',
    cuts_6: '/museum/videos/half/book2/book2_cut6.mp4',
    cuts_7: '/museum/videos/half/book2/book2_cut7.mp4',
    cuts_8: '/museum/videos/half/book2/book2_cut8.mp4',
    cuts_9: '/museum/videos/half/book2/book2_cut9.mp4',
    cuts_10: '/museum/videos/half/book2/book2_cut10.mp4',
    cuts_11: '/museum/videos/half/book2/book2_cut11.mp4',
    cuts_12: '/museum/videos/half/book2/book2_cut12.mp4',
    cuts_13: '/museum/videos/half/book2/book2_cut13.mp4',
    cuts_14: '/museum/videos/half/book2/book2_cut14.mp4',
    cuts_15: '/museum/videos/half/book2/book2_cut15.mp4',
    cuts_16: '/museum/videos/half/book2/book2_cut16.mp4',
    cuts_17: '/museum/videos/half/book2/book2_cut17.mp4',
    cuts_18: '/museum/videos/half/book2/book2_cut18.mp4',
    cuts_19: '/museum/videos/half/book2/book2_cut19.mp4',
    cuts_20: '/museum/videos/half/book2/book2_cut20.mp4',
    cuts_21: '/museum/videos/half/book2/book2_cut21.mp4',
    cuts_22: '/museum/videos/half/book2/book2_cut22.mp4',
    cuts_23: '/museum/videos/half/book2/book2_cut23.mp4',
    cuts_24: '/museum/videos/half/book2/book2_cut24.mp4',
    cuts_25: '/museum/videos/half/book2/book2_cut25.mp4',
    cuts_26: '/museum/videos/half/book2/book2_cut26.mp4',
    cuts_27: '/museum/videos/half/book2/book2_cut27.mp4',
    cuts_28: '/museum/videos/half/book2/book2_cut28.mp4',
    cuts_29: '/museum/videos/half/book2/book2_cut29.mp4',
    cuts_30: '/museum/videos/half/book2/book2_cut30.mp4',
    cuts_31: '/museum/videos/half/book2/book2_cut31.mp4',
    cuts_32: '/museum/videos/half/book2/book2_cut32.mp4',
    cuts_33: '/museum/videos/half/book2/book2_cut33.mp4',
    cuts_34: '/museum/videos/half/book2/book2_cut34.mp4',
    cuts_35: '/museum/videos/half/book2/book2_cut35.mp4',
    cuts_36: '/museum/videos/half/book2/book2_cut36.mp4',
    cuts_37: '/museum/videos/half/book2/book2_cut37.mp4',
  },
  book4: {
    cuts_1: '/museum/videos/half/book4/book4_cut1.mp4',
    cuts_2: '/museum/videos/half/book4/book4_cut2.mp4',
    cuts_3: '/museum/videos/half/book4/book4_cut3.mp4',
    cuts_4: '/museum/videos/half/book4/book4_cut4.mp4',
    cuts_5: '/museum/videos/half/book4/book4_cut5.mp4',
    cuts_6: '/museum/videos/half/book4/book4_cut6.mp4',
    cuts_7: '/museum/videos/half/book4/book4_cut7.mp4',
    cuts_8: '/museum/videos/half/book4/book4_cut8.mp4',
    cuts_9: '/museum/videos/half/book4/book4_cut9.mp4',
    cuts_10: '/museum/videos/half/book4/book4_cut10.mp4',
    cuts_11: '/museum/videos/half/book4/book4_cut11.mp4',
    cuts_12: '/museum/videos/half/book4/book4_cut12.mp4',
    cuts_13: '/museum/videos/half/book4/book4_cut13.mp4',
    cuts_14: '/museum/videos/half/book4/book4_cut14.mp4',
    cuts_15: '/museum/videos/half/book4/book4_cut15.mp4',
    cuts_16: '/museum/videos/half/book4/book4_cut16.mp4',
    cuts_17: '/museum/videos/half/book4/book4_cut17.mp4',
    cuts_18: '/museum/videos/half/book4/book4_cut18.mp4',
    cuts_19: '/museum/videos/half/book4/book4_cut19.mp4',
    cuts_20: '/museum/videos/half/book4/book4_cut20.mp4',
    cuts_21: '/museum/videos/half/book4/book4_cut21.mp4',
    cuts_22: '/museum/videos/half/book4/book4_cut22.mp4',
    cuts_23: '/museum/videos/half/book4/book4_cut23.mp4',
    cuts_24: '/museum/videos/half/book4/book4_cut24.mp4',
    cuts_25: '/museum/videos/half/book4/book4_cut25.mp4',
    cuts_26: '/museum/videos/half/book4/book4_cut26.mp4',
    cuts_27: '/museum/videos/half/book4/book4_cut27.mp4',
    cuts_28: '/museum/videos/half/book4/book4_cut28.mp4',
    cuts_29: '/museum/videos/half/book4/book4_cut29.mp4',
    cuts_30: '/museum/videos/half/book4/book4_cut30.mp4',
    cuts_31: '/museum/videos/half/book4/book4_cut31.mp4',
    cuts_32: '/museum/videos/half/book4/book4_cut32.mp4',
    cuts_33: '/museum/videos/half/book4/book4_cut33.mp4',
    cuts_34: '/museum/videos/half/book4/book4_cut34.mp4',
    cuts_35: '/museum/videos/half/book4/book4_cut35.mp4',
    cuts_36: '/museum/videos/half/book4/book4_cut36.mp4',
    cuts_37: '/museum/videos/half/book4/book4_cut37.mp4',
  },
  book5: {
    cuts_1: '/museum/videos/half/book5/book5_cut1.mp4',
    cuts_2: '/museum/videos/half/book5/book5_cut2.mp4',
    cuts_3: '/museum/videos/half/book5/book5_cut3.mp4',
    cuts_4: '/museum/videos/half/book5/book5_cut4.mp4',
    cuts_5: '/museum/videos/half/book5/book5_cut5.mp4',
    cuts_6: '/museum/videos/half/book5/book5_cut6.mp4',
    cuts_7: '/museum/videos/half/book5/book5_cut7.mp4',
    cuts_8: '/museum/videos/half/book5/book5_cut8.mp4',
    cuts_9: '/museum/videos/half/book5/book5_cut9.mp4',
    cuts_10: '/museum/videos/half/book5/book5_cut10.mp4',
    cuts_11: '/museum/videos/half/book5/book5_cut11.mp4',
    cuts_12: '/museum/videos/half/book5/book5_cut12.mp4',
    cuts_13: '/museum/videos/half/book5/book5_cut13.mp4',
    cuts_14: '/museum/videos/half/book5/book5_cut14.mp4',
    cuts_15: '/museum/videos/half/book5/book5_cut15.mp4',
    cuts_16: '/museum/videos/half/book5/book5_cut16.mp4',
    cuts_17: '/museum/videos/half/book5/book5_cut17.mp4',
    cuts_18: '/museum/videos/half/book5/book5_cut18.mp4',
    cuts_19: '/museum/videos/half/book5/book5_cut19.mp4',
    cuts_20: '/museum/videos/half/book5/book5_cut20.mp4',
    cuts_21: '/museum/videos/half/book5/book5_cut21.mp4',
    cuts_22: '/museum/videos/half/book5/book5_cut22.mp4',
    cuts_23: '/museum/videos/half/book5/book5_cut23.mp4',
    cuts_24: '/museum/videos/half/book5/book5_cut24.mp4',
    cuts_25: '/museum/videos/half/book5/book5_cut25.mp4',
    cuts_26: '/museum/videos/half/book5/book5_cut26.mp4',
    cuts_27: '/museum/videos/half/book5/book5_cut27.mp4',
    cuts_28: '/museum/videos/half/book5/book5_cut28.mp4',
    cuts_29: '/museum/videos/half/book5/book5_cut29.mp4',
    cuts_30: '/museum/videos/half/book5/book5_cut30.mp4',
    cuts_31: '/museum/videos/half/book5/book5_cut31.mp4',
    cuts_32: '/museum/videos/half/book5/book5_cut32.mp4',
    cuts_33: '/museum/videos/half/book5/book5_cut33.mp4',
    cuts_34: '/museum/videos/half/book5/book5_cut34.mp4',
    cuts_35: '/museum/videos/half/book5/book5_cut35.mp4',
    cuts_36: '/museum/videos/half/book5/book5_cut36.mp4',
    cuts_37: '/museum/videos/half/book5/book5_cut37.mp4',
  },
  book6: {
    cuts_1: '/museum/videos/half/book6/book6_cut1.mp4',
    cuts_2: '/museum/videos/half/book6/book6_cut2.mp4',
    cuts_3: '/museum/videos/half/book6/book6_cut3.mp4',
    cuts_4: '/museum/videos/half/book6/book6_cut4.mp4',
    cuts_5: '/museum/videos/half/book6/book6_cut5.mp4',
    cuts_6: '/museum/videos/half/book6/book6_cut6.mp4',
    cuts_7: '/museum/videos/half/book6/book6_cut7.mp4',
    cuts_8: '/museum/videos/half/book6/book6_cut8.mp4',
    cuts_9: '/museum/videos/half/book6/book6_cut9.mp4',
    cuts_10: '/museum/videos/half/book6/book6_cut10.mp4',
    cuts_11: '/museum/videos/half/book6/book6_cut11.mp4',
    cuts_12: '/museum/videos/half/book6/book6_cut12.mp4',
    cuts_13: '/museum/videos/half/book6/book6_cut13.mp4',
    cuts_14: '/museum/videos/half/book6/book6_cut14.mp4',
    cuts_15: '/museum/videos/half/book6/book6_cut15.mp4',
    cuts_16: '/museum/videos/half/book6/book6_cut16.mp4',
    cuts_17: '/museum/videos/half/book6/book6_cut17.mp4',
    cuts_18: '/museum/videos/half/book6/book6_cut18.mp4',
    cuts_19: '/museum/videos/half/book6/book6_cut19.mp4',
    cuts_20: '/museum/videos/half/book6/book6_cut20.mp4',
    cuts_21: '/museum/videos/half/book6/book6_cut21.mp4',
    cuts_22: '/museum/videos/half/book6/book6_cut22.mp4',
    cuts_23: '/museum/videos/half/book6/book6_cut23.mp4',
    cuts_24: '/museum/videos/half/book6/book6_cut24.mp4',
    cuts_25: '/museum/videos/half/book6/book6_cut25.mp4',
    cuts_26: '/museum/videos/half/book6/book6_cut26.mp4',
    cuts_27: '/museum/videos/half/book6/book6_cut27.mp4',
    cuts_28: '/museum/videos/half/book6/book6_cut28.mp4',
    cuts_29: '/museum/videos/half/book6/book6_cut29.mp4',
    cuts_30: '/museum/videos/half/book6/book6_cut30.mp4',
    cuts_31: '/museum/videos/half/book6/book6_cut31.mp4',
    cuts_32: '/museum/videos/half/book6/book6_cut32.mp4',
    cuts_33: '/museum/videos/half/book6/book6_cut33.mp4',
    cuts_34: '/museum/videos/half/book6/book6_cut34.mp4',
    cuts_35: '/museum/videos/half/book6/book6_cut35.mp4',
    cuts_36: '/museum/videos/half/book6/book6_cut36.mp4',
    cuts_37: '/museum/videos/half/book6/book6_cut37.mp4',
  },
  book10: {
    cuts_1: '/museum/videos/half/book10/book10_cut1.mp4',
    cuts_2: '/museum/videos/half/book10/book10_cut2.mp4',
    cuts_3: '/museum/videos/half/book10/book10_cut3.mp4',
    cuts_4: '/museum/videos/half/book10/book10_cut4.mp4',
    cuts_5: '/museum/videos/half/book10/book10_cut5.mp4',
    cuts_6: '/museum/videos/half/book10/book10_cut6.mp4',
    cuts_7: '/museum/videos/half/book10/book10_cut7.mp4',
    cuts_8: '/museum/videos/half/book10/book10_cut8.mp4',
    cuts_9: '/museum/videos/half/book10/book10_cut9.mp4',
    cuts_10: '/museum/videos/half/book10/book10_cut10.mp4',
    cuts_11: '/museum/videos/half/book10/book10_cut11.mp4',
    cuts_12: '/museum/videos/half/book10/book10_cut12.mp4',
    cuts_13: '/museum/videos/half/book10/book10_cut13.mp4',
    cuts_14: '/museum/videos/half/book10/book10_cut14.mp4',
    cuts_15: '/museum/videos/half/book10/book10_cut15.mp4',
    cuts_16: '/museum/videos/half/book10/book10_cut16.mp4',
    cuts_17: '/museum/videos/half/book10/book10_cut17.mp4',
    cuts_18: '/museum/videos/half/book10/book10_cut18.mp4',
    cuts_19: '/museum/videos/half/book10/book10_cut19.mp4',
    cuts_20: '/museum/videos/half/book10/book10_cut20.mp4',
    cuts_21: '/museum/videos/half/book10/book10_cut21.mp4',
    cuts_22: '/museum/videos/half/book10/book10_cut22.mp4',
    cuts_23: '/museum/videos/half/book10/book10_cut23.mp4',
    cuts_24: '/museum/videos/half/book10/book10_cut24.mp4',
    cuts_25: '/museum/videos/half/book10/book10_cut25.mp4',
    cuts_26: '/museum/videos/half/book10/book10_cut26.mp4',
    cuts_27: '/museum/videos/half/book10/book10_cut27.mp4',
    cuts_28: '/museum/videos/half/book10/book10_cut28.mp4',
    cuts_29: '/museum/videos/half/book10/book10_cut29.mp4',
    cuts_30: '/museum/videos/half/book10/book10_cut30.mp4',
    cuts_31: '/museum/videos/half/book10/book10_cut31.mp4',
    cuts_32: '/museum/videos/half/book10/book10_cut32.mp4',
    cuts_33: '/museum/videos/half/book10/book10_cut33.mp4',
    cuts_34: '/museum/videos/half/book10/book10_cut34.mp4',
    cuts_35: '/museum/videos/half/book10/book10_cut35.mp4',
    cuts_36: '/museum/videos/half/book10/book10_cut36.mp4',
    cuts_37: '/museum/videos/half/book10/book10_cut37.mp4',
  },
}

export const VIDEOS_FULL = {
  book1: {
    cuts_1: '/museum/videos/full/book1/book1_cut1.mp4',
    cuts_2: '/museum/videos/full/book1/book1_cut2.mp4',
    cuts_3: '/museum/videos/full/book1/book1_cut3.mp4',
    cuts_4: '/museum/videos/full/book1/book1_cut4.mp4',
    cuts_5: '/museum/videos/full/book1/book1_cut5.mp4',
    cuts_6: '/museum/videos/full/book1/book1_cut6.mp4',
    cuts_7: '/museum/videos/full/book1/book1_cut7.mp4',
    cuts_8: '/museum/videos/full/book1/book1_cut8.mp4',
    cuts_9: '/museum/videos/full/book1/book1_cut9.mp4',
    cuts_10: '/museum/videos/full/book1/book1_cut10.mp4',
    cuts_11: '/museum/videos/full/book1/book1_cut11.mp4',
    cuts_12: '/museum/videos/full/book1/book1_cut12.mp4',
    cuts_13: '/museum/videos/full/book1/book1_cut13.mp4',
    cuts_14: '/museum/videos/full/book1/book1_cut14.mp4',
    cuts_15: '/museum/videos/full/book1/book1_cut15.mp4',
    cuts_16: '/museum/videos/full/book1/book1_cut16.mp4',
    cuts_17: '/museum/videos/full/book1/book1_cut17.mp4',
    cuts_18: '/museum/videos/full/book1/book1_cut18.mp4',
    cuts_19: '/museum/videos/full/book1/book1_cut19.mp4',
    cuts_20: '/museum/videos/full/book1/book1_cut20.mp4',
    cuts_21: '/museum/videos/full/book1/book1_cut21.mp4',
    cuts_22: '/museum/videos/full/book1/book1_cut22.mp4',
    cuts_23: '/museum/videos/full/book1/book1_cut23.mp4',
    cuts_24: '/museum/videos/full/book1/book1_cut24.mp4',
    cuts_25: '/museum/videos/full/book1/book1_cut25.mp4',
    cuts_26: '/museum/videos/full/book1/book1_cut26.mp4',
    cuts_27: '/museum/videos/full/book1/book1_cut27.mp4',
    cuts_28: '/museum/videos/full/book1/book1_cut28.mp4',
    cuts_29: '/museum/videos/full/book1/book1_cut29.mp4',
    cuts_30: '/museum/videos/full/book1/book1_cut30.mp4',
    cuts_31: '/museum/videos/full/book1/book1_cut31.mp4',
    cuts_32: '/museum/videos/full/book1/book1_cut32.mp4',
    cuts_33: '/museum/videos/full/book1/book1_cut33.mp4',
    cuts_34: '/museum/videos/full/book1/book1_cut34.mp4',
    cuts_35: '/museum/videos/full/book1/book1_cut35.mp4',
    cuts_36: '/museum/videos/full/book1/book1_cut36.mp4',
    cuts_37: '/museum/videos/full/book1/book1_cut37.mp4',
  },
  book2: {
    cuts_1: '/museum/videos/full/book2/book2_cut1.mp4',
    cuts_2: '/museum/videos/full/book2/book2_cut2.mp4',
    cuts_3: '/museum/videos/full/book2/book2_cut3.mp4',
    cuts_4: '/museum/videos/full/book2/book2_cut4.mp4',
    cuts_5: '/museum/videos/full/book2/book2_cut5.mp4',
    cuts_6: '/museum/videos/full/book2/book2_cut6.mp4',
    cuts_7: '/museum/videos/full/book2/book2_cut7.mp4',
    cuts_8: '/museum/videos/full/book2/book2_cut8.mp4',
    cuts_9: '/museum/videos/full/book2/book2_cut9.mp4',
    cuts_10: '/museum/videos/full/book2/book2_cut10.mp4',
    cuts_11: '/museum/videos/full/book2/book2_cut11.mp4',
    cuts_12: '/museum/videos/full/book2/book2_cut12.mp4',
    cuts_13: '/museum/videos/full/book2/book2_cut13.mp4',
    cuts_14: '/museum/videos/full/book2/book2_cut14.mp4',
    cuts_15: '/museum/videos/full/book2/book2_cut15.mp4',
    cuts_16: '/museum/videos/full/book2/book2_cut16.mp4',
    cuts_17: '/museum/videos/full/book2/book2_cut17.mp4',
    cuts_18: '/museum/videos/full/book2/book2_cut18.mp4',
    cuts_19: '/museum/videos/full/book2/book2_cut19.mp4',
    cuts_20: '/museum/videos/full/book2/book2_cut20.mp4',
    cuts_21: '/museum/videos/full/book2/book2_cut21.mp4',
    cuts_22: '/museum/videos/full/book2/book2_cut22.mp4',
    cuts_23: '/museum/videos/full/book2/book2_cut23.mp4',
    cuts_24: '/museum/videos/full/book2/book2_cut24.mp4',
    cuts_25: '/museum/videos/full/book2/book2_cut25.mp4',
    cuts_26: '/museum/videos/full/book2/book2_cut26.mp4',
    cuts_27: '/museum/videos/full/book2/book2_cut27.mp4',
    cuts_28: '/museum/videos/full/book2/book2_cut28.mp4',
    cuts_29: '/museum/videos/full/book2/book2_cut29.mp4',
    cuts_30: '/museum/videos/full/book2/book2_cut30.mp4',
    cuts_31: '/museum/videos/full/book2/book2_cut31.mp4',
    cuts_32: '/museum/videos/full/book2/book2_cut32.mp4',
    cuts_33: '/museum/videos/full/book2/book2_cut33.mp4',
    cuts_34: '/museum/videos/full/book2/book2_cut34.mp4',
    cuts_35: '/museum/videos/full/book2/book2_cut35.mp4',
    cuts_36: '/museum/videos/full/book2/book2_cut36.mp4',
    cuts_37: '/museum/videos/full/book2/book2_cut37.mp4',
  },
  book4: {
    cuts_1: '/museum/videos/full/book4/book4_cut1.mp4',
    cuts_2: '/museum/videos/full/book4/book4_cut2.mp4',
    cuts_3: '/museum/videos/full/book4/book4_cut3.mp4',
    cuts_4: '/museum/videos/full/book4/book4_cut4.mp4',
    cuts_5: '/museum/videos/full/book4/book4_cut5.mp4',
    cuts_6: '/museum/videos/full/book4/book4_cut6.mp4',
    cuts_7: '/museum/videos/full/book4/book4_cut7.mp4',
    cuts_8: '/museum/videos/full/book4/book4_cut8.mp4',
    cuts_9: '/museum/videos/full/book4/book4_cut9.mp4',
    cuts_10: '/museum/videos/full/book4/book4_cut10.mp4',
    cuts_11: '/museum/videos/full/book4/book4_cut11.mp4',
    cuts_12: '/museum/videos/full/book4/book4_cut12.mp4',
    cuts_13: '/museum/videos/full/book4/book4_cut13.mp4',
    cuts_14: '/museum/videos/full/book4/book4_cut14.mp4',
    cuts_15: '/museum/videos/full/book4/book4_cut15.mp4',
    cuts_16: '/museum/videos/full/book4/book4_cut16.mp4',
    cuts_17: '/museum/videos/full/book4/book4_cut17.mp4',
    cuts_18: '/museum/videos/full/book4/book4_cut18.mp4',
    cuts_19: '/museum/videos/full/book4/book4_cut19.mp4',
    cuts_20: '/museum/videos/full/book4/book4_cut20.mp4',
    cuts_21: '/museum/videos/full/book4/book4_cut21.mp4',
    cuts_22: '/museum/videos/full/book4/book4_cut22.mp4',
    cuts_23: '/museum/videos/full/book4/book4_cut23.mp4',
    cuts_24: '/museum/videos/full/book4/book4_cut24.mp4',
    cuts_25: '/museum/videos/full/book4/book4_cut25.mp4',
    cuts_26: '/museum/videos/full/book4/book4_cut26.mp4',
    cuts_27: '/museum/videos/full/book4/book4_cut27.mp4',
    cuts_28: '/museum/videos/full/book4/book4_cut28.mp4',
    cuts_29: '/museum/videos/full/book4/book4_cut29.mp4',
    cuts_30: '/museum/videos/full/book4/book4_cut30.mp4',
    cuts_31: '/museum/videos/full/book4/book4_cut31.mp4',
    cuts_32: '/museum/videos/full/book4/book4_cut32.mp4',
    cuts_33: '/museum/videos/full/book4/book4_cut33.mp4',
    cuts_34: '/museum/videos/full/book4/book4_cut34.mp4',
    cuts_35: '/museum/videos/full/book4/book4_cut35.mp4',
    cuts_36: '/museum/videos/full/book4/book4_cut36.mp4',
    cuts_37: '/museum/videos/full/book4/book4_cut37.mp4',
  },
  book5: {
    cuts_1: '/museum/videos/full/book5/book5_cut1.mp4',
    cuts_2: '/museum/videos/full/book5/book5_cut2.mp4',
    cuts_3: '/museum/videos/full/book5/book5_cut3.mp4',
    cuts_4: '/museum/videos/full/book5/book5_cut4.mp4',
    cuts_5: '/museum/videos/full/book5/book5_cut5.mp4',
    cuts_6: '/museum/videos/full/book5/book5_cut6.mp4',
    cuts_7: '/museum/videos/full/book5/book5_cut7.mp4',
    cuts_8: '/museum/videos/full/book5/book5_cut8.mp4',
    cuts_9: '/museum/videos/full/book5/book5_cut9.mp4',
    cuts_10: '/museum/videos/full/book5/book5_cut10.mp4',
    cuts_11: '/museum/videos/full/book5/book5_cut11.mp4',
    cuts_12: '/museum/videos/full/book5/book5_cut12.mp4',
    cuts_13: '/museum/videos/full/book5/book5_cut13.mp4',
    cuts_14: '/museum/videos/full/book5/book5_cut14.mp4',
    cuts_15: '/museum/videos/full/book5/book5_cut15.mp4',
    cuts_16: '/museum/videos/full/book5/book5_cut16.mp4',
    cuts_17: '/museum/videos/full/book5/book5_cut17.mp4',
    cuts_18: '/museum/videos/full/book5/book5_cut18.mp4',
    cuts_19: '/museum/videos/full/book5/book5_cut19.mp4',
    cuts_20: '/museum/videos/full/book5/book5_cut20.mp4',
    cuts_21: '/museum/videos/full/book5/book5_cut21.mp4',
    cuts_22: '/museum/videos/full/book5/book5_cut22.mp4',
    cuts_23: '/museum/videos/full/book5/book5_cut23.mp4',
    cuts_24: '/museum/videos/full/book5/book5_cut24.mp4',
    cuts_25: '/museum/videos/full/book5/book5_cut25.mp4',
    cuts_26: '/museum/videos/full/book5/book5_cut26.mp4',
    cuts_27: '/museum/videos/full/book5/book5_cut27.mp4',
    cuts_28: '/museum/videos/full/book5/book5_cut28.mp4',
    cuts_29: '/museum/videos/full/book5/book5_cut29.mp4',
    cuts_30: '/museum/videos/full/book5/book5_cut30.mp4',
    cuts_31: '/museum/videos/full/book5/book5_cut31.mp4',
    cuts_32: '/museum/videos/full/book5/book5_cut32.mp4',
    cuts_33: '/museum/videos/full/book5/book5_cut33.mp4',
    cuts_34: '/museum/videos/full/book5/book5_cut34.mp4',
    cuts_35: '/museum/videos/full/book5/book5_cut35.mp4',
    cuts_36: '/museum/videos/full/book5/book5_cut36.mp4',
    cuts_37: '/museum/videos/full/book5/book5_cut37.mp4',
  },
  book6: {
    cuts_1: '/museum/videos/full/book6/book6_cut1.mp4',
    cuts_2: '/museum/videos/full/book6/book6_cut2.mp4',
    cuts_3: '/museum/videos/full/book6/book6_cut3.mp4',
    cuts_4: '/museum/videos/full/book6/book6_cut4.mp4',
    cuts_5: '/museum/videos/full/book6/book6_cut5.mp4',
    cuts_6: '/museum/videos/full/book6/book6_cut6.mp4',
    cuts_7: '/museum/videos/full/book6/book6_cut7.mp4',
    cuts_8: '/museum/videos/full/book6/book6_cut8.mp4',
    cuts_9: '/museum/videos/full/book6/book6_cut9.mp4',
    cuts_10: '/museum/videos/full/book6/book6_cut10.mp4',
    cuts_11: '/museum/videos/full/book6/book6_cut11.mp4',
    cuts_12: '/museum/videos/full/book6/book6_cut12.mp4',
    cuts_13: '/museum/videos/full/book6/book6_cut13.mp4',
    cuts_14: '/museum/videos/full/book6/book6_cut14.mp4',
    cuts_15: '/museum/videos/full/book6/book6_cut15.mp4',
    cuts_16: '/museum/videos/full/book6/book6_cut16.mp4',
    cuts_17: '/museum/videos/full/book6/book6_cut17.mp4',
    cuts_18: '/museum/videos/full/book6/book6_cut18.mp4',
    cuts_19: '/museum/videos/full/book6/book6_cut19.mp4',
    cuts_20: '/museum/videos/full/book6/book6_cut20.mp4',
    cuts_21: '/museum/videos/full/book6/book6_cut21.mp4',
    cuts_22: '/museum/videos/full/book6/book6_cut22.mp4',
    cuts_23: '/museum/videos/full/book6/book6_cut23.mp4',
    cuts_24: '/museum/videos/full/book6/book6_cut24.mp4',
    cuts_25: '/museum/videos/full/book6/book6_cut25.mp4',
    cuts_26: '/museum/videos/full/book6/book6_cut26.mp4',
    cuts_27: '/museum/videos/full/book6/book6_cut27.mp4',
    cuts_28: '/museum/videos/full/book6/book6_cut28.mp4',
    cuts_29: '/museum/videos/full/book6/book6_cut29.mp4',
    cuts_30: '/museum/videos/full/book6/book6_cut30.mp4',
    cuts_31: '/museum/videos/full/book6/book6_cut31.mp4',
    cuts_32: '/museum/videos/full/book6/book6_cut32.mp4',
    cuts_33: '/museum/videos/full/book6/book6_cut33.mp4',
    cuts_34: '/museum/videos/full/book6/book6_cut34.mp4',
    cuts_35: '/museum/videos/full/book6/book6_cut35.mp4',
    cuts_36: '/museum/videos/full/book6/book6_cut36.mp4',
    cuts_37: '/museum/videos/full/book6/book6_cut37.mp4',
  },
  book10: {
    cuts_1: '/museum/videos/full/book10/book10_cut1.mp4',
    cuts_2: '/museum/videos/full/book10/book10_cut2.mp4',
    cuts_3: '/museum/videos/full/book10/book10_cut3.mp4',
    cuts_4: '/museum/videos/full/book10/book10_cut4.mp4',
    cuts_5: '/museum/videos/full/book10/book10_cut5.mp4',
    cuts_6: '/museum/videos/full/book10/book10_cut6.mp4',
    cuts_7: '/museum/videos/full/book10/book10_cut7.mp4',
    cuts_8: '/museum/videos/full/book10/book10_cut8.mp4',
    cuts_9: '/museum/videos/full/book10/book10_cut9.mp4',
    cuts_10: '/museum/videos/full/book10/book10_cut10.mp4',
    cuts_11: '/museum/videos/full/book10/book10_cut11.mp4',
    cuts_12: '/museum/videos/full/book10/book10_cut12.mp4',
    cuts_13: '/museum/videos/full/book10/book10_cut13.mp4',
    cuts_14: '/museum/videos/full/book10/book10_cut14.mp4',
    cuts_15: '/museum/videos/full/book10/book10_cut15.mp4',
    cuts_16: '/museum/videos/full/book10/book10_cut16.mp4',
    cuts_17: '/museum/videos/full/book10/book10_cut17.mp4',
    cuts_18: '/museum/videos/full/book10/book10_cut18.mp4',
    cuts_19: '/museum/videos/full/book10/book10_cut19.mp4',
    cuts_20: '/museum/videos/full/book10/book10_cut20.mp4',
    cuts_21: '/museum/videos/full/book10/book10_cut21.mp4',
    cuts_22: '/museum/videos/full/book10/book10_cut22.mp4',
    cuts_23: '/museum/videos/full/book10/book10_cut23.mp4',
    cuts_24: '/museum/videos/full/book10/book10_cut24.mp4',
    cuts_25: '/museum/videos/full/book10/book10_cut25.mp4',
    cuts_26: '/museum/videos/full/book10/book10_cut26.mp4',
    cuts_27: '/museum/videos/full/book10/book10_cut27.mp4',
    cuts_28: '/museum/videos/full/book10/book10_cut28.mp4',
    cuts_29: '/museum/videos/full/book10/book10_cut29.mp4',
    cuts_30: '/museum/videos/full/book10/book10_cut30.mp4',
    cuts_31: '/museum/videos/full/book10/book10_cut31.mp4',
    cuts_32: '/museum/videos/full/book10/book10_cut32.mp4',
    cuts_33: '/museum/videos/full/book10/book10_cut33.mp4',
    cuts_34: '/museum/videos/full/book10/book10_cut34.mp4',
    cuts_35: '/museum/videos/full/book10/book10_cut35.mp4',
    cuts_36: '/museum/videos/full/book10/book10_cut36.mp4',
    cuts_37: '/museum/videos/full/book10/book10_cut37.mp4',
  },
}
export const VIDEOS = IS_FULLSCREEN_VIDEOS ? VIDEOS_FULL  : VIDEOS_HALF
export type BookKeys = keyof typeof VIDEOS_HALF
export  type VideoKeys = keyof typeof VIDEOS_HALF.book1
export type BookVideoKeys = `${BookKeys}.${VideoKeys}`
