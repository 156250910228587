import create from 'zustand'
type Action = 'NEXT_PAGE' | 'TOGGLE_PAGE' | undefined;
export const museumContextStore = create((set: any) => ({
  action: undefined as Action,
  debug: process.env.REACT_APP_IS_DEBUG === "true",
  fullScreen: true,
  setTurn: () => {
    return set({ action: 'NEXT_PAGE' })
  },
  setAction: () => {
    return set({ action: 'TOGGLE_PAGE' })
  },
  clearAction: () => {
    return set({ action: undefined })
  },
  toggleDebug: (debug: boolean) => set({ debug: debug }),
  toggleFullScreen: (fs: boolean) => set({ fullScreen: fs }),
}))
