import { useEffect, useRef, useState } from "react";
import * as THREE from "three"
import { useMove } from "@use-gesture/react";
import { CursorPlane } from "@features/360/components/Cursor";
import { Vector3 } from "@react-three/fiber";
import { useMapStore } from "@features/contextStore";
import {Text}  from "@react-three/drei";
type Props = {
  position: Vector3;
  args?: [number, number, number, number];
  rotation?: number;
  onClick?:  number;
}
export function FloorPlane({position, args, rotation, onClick}: Props) {
  const [color, setColor] = useState( Math.random() * 0xffffff)
  const [hovered, hover] = useState(false)
  const [cursor, setCursor] = useState<Vector3>([0,0,0])
   const {debug , setMap, ...other} = useMapStore();
  const ref = useRef<any>(null!)
  const bind = useMove(
    ({ active, movement: [x, y], timeStamp, event }: any) => {
      if (active ) {
        setCursor(event.point)
      }
      return timeStamp;
    },
    { delay: true }
  ) as any;
  const handleClick = (e: any) => {
    if(!onClick) {return}
    setMap(onClick);
  }
  const handleDoubleClick = (e: any) => {
    setColor(Math.random() * 0xffffff) 
  }

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : other.rotation ? 'move' : 'auto';
  }, [hovered]);
  
  return (
    <>
   
    <mesh ref={ref} position={position} rotation={[Math.PI / 2, 0, 0]} scale={[1, 1, 1]} 
    onDoubleClick={handleDoubleClick}
    onClick={handleClick}
    onPointerOver={(event) => hover(true)}
    onPointerOut={(event) => hover(false)}  >
      {debug ? <Text
          scale={[80, 80, 80]}
          color="white"
          anchorX="center"
          anchorY="middle"
          rotation={[Math.PI,0,  -Math.PI/2]}
        >
          [{onClick}]
        </Text> :
        null }
        <planeGeometry args={args} /> 
      <meshPhongMaterial transparent={!debug} opacity={debug ? 0.8 : 0 } wireframe={debug  ?  !hovered : false} color={debug ? color : undefined} side={THREE.DoubleSide} />
     
    </mesh>
    
    <CursorPlane visible={hovered} position={cursor} rotation={rotation}/>
    </>
  );
}


