import ThreeSixtyApp from '@features/360/app/App'
import CookiesPopup from '@features/360/components/CookiesPopup'
import RegistrationPage from '@features/360/containers/RegistrationPage'
import MuseumsApp from '@features/Museum/app/App'
import { ThemeProvider } from '@theme/styles/theme/ThemeProvider'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css'
import './theme/animation.css'
import './theme/global.css'

const App = () => {
  /*  Condition to decide whether museum app or 360 app is to be served */
  const isInHouseApp =
    ['/museum', '/museum/'].includes(window.location.pathname) ||
    process.env.REACT_APP_ONLY_MUSEUM === 'true'

  if (isInHouseApp) {
    return (
      <ThemeProvider>
        <MuseumsApp />
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider>
      <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RegistrationPage />} />
          <Route path="/360" element={<ThreeSixtyApp />} />
        </Routes>
      </BrowserRouter>
      <CookiesPopup />
      </>
    </ThemeProvider>
  )
}

export default App
