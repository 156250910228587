import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    mainScreenSmall:{
        width: "100vw",
        height: "100%",
        position: "relative",
        zIndex: "1",
        overflow: 'hidden',
    },
    mainScreen: {
        marginTop: -40,
        width: "100vw",
        height: "100%",
        position: "relative",
        zIndex: "1",
        overflow: 'hidden',
    },
    bottomtxt: {
        position: "fixed",
        bottom: "0",
        left: 0,
        right: 0,
        background: "#FFFFFF",
        padding: "15px 21px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    bottomButton:{
        position: "fixed",
        bottom: "0",
        left: 0,
        right: 0,
        padding: "15px 21px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    txt: {
        fontSize: " 11px !important",
        lineHeight: "15px !important",


    },
    title: {
        color: "#323232"
    },
    desc: {
        marginLeft: "10px !important",
        color: "#9F854C",
    },
    progressbar: {
        position: 'absolute',
        bottom: 20,
        width: "-webkit-fill-available",
        padding: '10px 100px',
        ['@media (max-width:576px)']: { 
            paddingTop: "0",
            paddingBottom: "59px",
            padding: '0px 0px',
            paddingLeft: '0px',
        }
    },
    skipButton: {
        position: 'absolute !important' as any,
        right: 10,
        bottom: 40,
        color: '#9f854c !important' as any,
        ['@media (max-width:576px)']: { 
            '& img': {
                width: 55,
                height: 55,
            },
           
            bottom: 95,
            right: -10,
        }
    },
    closeButton: {
        position: 'absolute !important' as any,
        right: 10,
        bottom: 40,
        color: '#9f854c !important' as any,
        ['@media (max-width:576px)']: { 
            '& img': {
                width: 55,
                height: 55,
            },
           
            bottom: 95,
            right: -10,
        }
    }
});