import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    "play-btn-container": {
        display: "flex",
        position: "absolute",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        top: "80%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "transparent",
        height: "100vh",
        width: "100vw",
        borderRadius: "10px",
        zIndex: "9",
        padding: "0",
        cursor: 'pointer',
        ['@media (max-width:576px)']: { 
            top: "40%",
        }
    },
    playbtn: {
        padding: "0 !important",
        display: "flex",
        // alignSelf: "center",
        flexDirection: "column",
        fontSize: "16px !important",
        fontWeight: "500",
        textTransform: "uppercase",
    }
});